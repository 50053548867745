/*!
 *   Devco Template v1.0.0
 *   Author: Devco
!*/


/*  CORE IMPORT
========================== */
@import 'core';

html, body {
  @media screen and (max-width : 767px) {
    height: auto;
    min-height: 100%;
  }
}

body {
  font-family: $font-montserrat !important;
  color: $clr-carbonDK;
  font-size: 13px;
  line-height: 1.5;
}

.pull-left { float: left;}
.pull-right { float: right;}

 .text-center { text-align: center !important }

/* Tooltips & Popovers */
.popover, .tooltip-inner {
  color: #555555;
  line-height: 1.5em;
  background: #FFFFFF;
  border: none;
  border-radius: 3px;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.popover {
  padding: 0;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  &.left > .arrow, &.right > .arrow, &.top > .arrow, &.bottom > .arrow {
    border: none;
  }
  .popover-title {
    background-color: #FFFFFF;
    border: none;
    padding: 15px 15px 5px;
    font-size: 1.3em;
  }
  .popover-content {
    padding: 10px 15px 15px;
    line-height: 1.4;
  }
}

.tooltip {
  opacity: 0;
  transition: opacity, transform .2s ease;
  -webkit-transform: translate3d(0, 5px, 0);
  -moz-transform: translate3d(0, 5px, 0);
  -o-transform: translate3d(0, 5px, 0);
  -ms-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  &.in {
    opacity: 1;
    -webkit-transform: translate3d(0, 0px, 0);
    -moz-transform: translate3d(0, 0px, 0);
    -o-transform: translate3d(0, 0px, 0);
    -ms-transform: translate3d(0, 0px, 0);
    transform: translate3d(0, 0px, 0);
  }
  &.left .tooltip-arrow {
    border-left-color: #FFFFFF;
  }
  &.right .tooltip-arrow {
    border-right-color: #FFFFFF;
  }
  &.top .tooltip-arrow {
    border-top-color: #FFFFFF;
  }
  &.bottom .tooltip-arrow {
    border-bottom-color: #FFFFFF;
  }
  .tooltip-inner {
    padding: 10px 15px;
    min-width: 130px;
  }
}


.multi-wrapper {
  border: 1px solid #E0E0E0;
  border-radius: 2px;
  .search-input {
    height: 46px;
    padding: 12px 16px;
    line-height: 1.733333;
    font-size: 15px;
    border-bottom: 1px solid #E0E0E0;
  }
  .non-selected-wrapper {
    padding: 12px 16px;
  }
}

// LOADER
.pace {
  .pace-progress {
    background: $clr-yellow;
    height: 3px;
  }
  .pace-progress-inner {
    box-shadow: inset -50px 0px 50px -50px $clr-yellowDK;
  }
  .pace-activity {
    border-top-color: #FFF;
    border-left-color: #FFF;
  }
}

.modal {
  .modal-dialog {
    .modal-content {
      border: none;
      border-radius: 0;
      .modal-header {
        text-align: center;
        text-transform: uppercase;
        border-bottom: none;
        padding-bottom: 0;
        .modal-title {
          padding-top: 20px;
          font-size: 18px;
        }
      }
      .modal-body {
        padding-top: 0;
        .form-group {
          &:last-child() {
            margin-bottom: 0 !important;
          }
        }
      }
    }
    &.modal-xl {
      width: 900px;
    }
    @media (min-width: 1680px) {
      &.modal-xl {
        width: 1400px;
      }
    }
  }
}

.ui-widget.ui-widget-content {
  border-color: $clr-greyLT;
  border-radius: 0 !important;
  padding: 5px;
  box-shadow: 0 12px 20px -10px rgba(0, 0, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 0, 0, 0.2);
  .ui-datepicker-header {
    background: transparent;
    border: none;
    a {
      top: 8px !important;
      span {

      }
    }
    select {
      font-family: $font-montserrat;
      font-weight: 400 !important;
      color: $clr-carbonLT;
      padding: 6px 0;
      background-color: #fff;
      border: 1px solid $clr-grey;
      border-radius: 2px;
      box-shadow: none;
      transition: border-color ease-in-out .15s;
    }
  }
  .ui-datepicker-buttonpane {
    button {
      border-radius: 0;
      margin-top: 12px;
      background-color: $clr-primary;
      font-family: $font-montserrat;
      font-weight: 400 !important;
      color: #FFF;
      border: none;
    }
  }
}

.notifyjs-corner {
  top: auto !important;
  bottom: 0px;
  .notifyjs-wrapper {
    .notifyjs-container {
      .notifyjs-bootstrap-success {
        padding: 15px 25px !important;
        background-color: $clr-success !important;
        border: none;
        font-size: 14px;
        color: #FFF;
        background-image: none;
        span {
          text-shadow: none;
        }
      }
      .notifyjs-bootstrap-warning {
        padding: 15px 25px !important;
        background-color: $clr-warning !important;
        border: none;
        font-size: 14px;
        color: #FFF;
        background-image: none;
        span {
          text-shadow: none;
        }
      }
      .notifyjs-bootstrap-danger {
        padding: 15px 25px !important;
        background-color: $clr-danger !important;
        border: none;
        font-size: 14px;
        color: #FFF;
        background-image: none;
        span {
          text-shadow: none;
        }
      }
    }
  }
}

.dropzone {
  border: 1px solid #e6e6e6;
}

.month_year_datepicker {
  .ui-datepicker-calendar {
    display: none;
  }
}

// TABLAS
.table {
  width: 100% !important;
  thead {
    tr {
      th {
        font-size: 12px;
        font-weight: 700;
        color: $clr-carbon;
        text-transform: uppercase;
        border-bottom: 1px solid rgb(228, 231, 237);
        padding: 13px 10px;
        vertical-align: middle;
        text-align: left;
        @media screen and (max-width : 991px) {
          font-size: 10px;
        }
        &.sorting, &.noSort {
          padding-right: 10px !important;
          opacity: 0.6;
          &:before, &:after {
            right: 0 !important;
            content: "";
          }
        }
        &:nth-child(1), &:nth-child(1).no-display + th:not(.no-display) {
          padding-left: 24px;
        }
      }
    }
  }
  tbody {
    tr {
      &.rowEstatusPagado {
        -webkit-box-shadow: inset 6px 0px 0px -1px $clr-blue;
        -moz-box-shadow: inset 6px 0px 0px -1px $clr-blue;
        box-shadow: inset 6px 0px 0px -1px $clr-blue;
        td:not(:last-child) {
          cursor: pointer;
        }
      }
      &.rowEstatusVigente {
        -webkit-box-shadow: inset 6px 0px 0px -1px $clr-success;
        -moz-box-shadow: inset 6px 0px 0px -1px $clr-success;
        box-shadow: inset 6px 0px 0px -1px $clr-success;
        td:not(:last-child) {
          cursor: pointer;
        }
      }
      th, td {
        font-size: 12px;
        color: $clr-carbon;
        text-transform: uppercase;
        border-bottom: 1px solid rgb(228, 231, 237);
        padding: 13px 10px;
        vertical-align: middle;
        //text-align: left;
        @media screen and (max-width : 991px) {
          font-size: 10px;
        }
        textarea {
          min-height: 34px;
          height: 34px;
          resize: vertical !important;
        }
      }
      td {
        font-size: 13px;
        &:nth-child(1), &:nth-child(1).no-display + td:not(.no-display) {
          padding-left: 24px;
        }
        &.tdStatus {
          i {
            font-size: 20px;
            float: left;
          }
          span {
            line-height: 20px;
            display: inline-block;
            float: left;
            margin-left: 5px;
          }
        }
        &.dataTables_empty {
          text-align: center;
        }
        .dropdown {
          a {
            &.menu {
              opacity: .75;
              &:hover {
                color: inherit;
                opacity: 1;
              }
            }
          }
          &.open {
            a {
              &.menu {
                opacity: 1;
              }
            }
          }
          .dropdown-item {
            position: relative;
            padding: 3px 50px 3px 20px;
            i {
              position: absolute;
              right: 8px;
              top: 6px;
              width: 22px;
              height: 22px;
              line-height: 22px;
              text-align: center;
              font-size: 18px;
            }
            span {
              font-size: 13px;
              position: relative;
              display: block;
              padding: 0;
              line-height: 30px;
            }
            span + i {
              padding-right: 20px;
            }
            &:hover {
              background-color: transparent !important;
              i {
                color: $clr-primary;
                &.cerrar, &.aceptar {
                  color: $clr-green;
                }
                &.editar {
                  color: $clr-yellow;
                }
                &.eliminar {
                  color: $clr-red;
                }
              }
            }
          }
        }
        a.text-primary {
          font-size: 13px;
          font-weight: 700;
          transition: color 0.3s ease;
          &:hover {
            color: $clr-primaryLT !important;
          }
        }
        small {
          display: block;
        }
        .img {
          width: 32px;
          height: 32px;
          margin: auto;
          background-size: cover;
          background-position: center;
          display: block;
          border-radius: 0.25rem;
        }
      }
    }
  }
  &.input-table {
    thead, tbody {
      tr {
        th, td {
          text-align: left
        }
      }
    }
    thead {
      tr {
        th {
          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 10px 5px 0px;
          border-top: none;
          border-bottom: none;
        }
      }
    }
  }
}
.table-responsive {
  @media screen and (max-width : 767px) {
    border: none;
  }
}
.dataTables_wrapper {
  .mdl-grid {
    padding: 0 !important;
    &:first-child {
      display: none;
    }
    .mdl-cell {
      margin: 0 !important;
      &.mdl-cell-4-col {
        padding: 15px;
        @media screen and (max-width : 767px) {
          .dataTables_info {
            display: none;
          }
        }
      }
      &.mdl-cell-12-col {
        width: 100% !important;
      }
    }
  }
  .pagination {
    button {
      border-radius: 0;
      box-shadow: none;
    }
  }
}
.table-responsive {
  margin: 0;
  .table {
    thead {
      tr {
        th {
          &:first-child {
            padding-left: 24px;
          }
        }
      }
    }
    tbody {
      tr {
        td {

          ul {
            padding: 0;
            margin: 0;
            li {
              display: inline-block;
              i {
                font-size: 18px;
                padding-left: 5px;
                padding-right: 5px;
              }
            }
          }
        }
      }
    }
  }
  #item-pnl {
    input:read-only {
      background-color: transparent;
      border-color: transparent;
      color: $clr-carbon;
    }
  }
}
.pagination {
  .mdl-button--raised.mdl-button--colored {
    background: $clr-primary;
    &:hover {
      background: $clr-primaryDK;
    }
  }
}

.select2 {
  &.select2-container {
    display: block;
    font-size: 16px;
    &.select2-container--default {
      .select2-selection--single {
        color: $clr-carbon;
        background-color: $clr-clear;
        border: 1px solid #e6e6e6;
        border-radius: 0px;
        -webkit-box-shadow: none;
        box-shadow: none;
        -webkit-transition: all .15s ease-out;
        transition: all .15s ease-out;
        height: 44px;
        &:focus {
          border-color: $clr-grey;
          background-color: $clr-clear;
          -webkit-box-shadow: none;
          box-shadow: none;
        }
        .select2-selection__rendered {
          padding: 8px 20px 6px 12px;
          font-size: 16px;
        }
        .select2-selection__arrow {
          top: 8px;
        }
      }
      &.select2-container--open {
        .select2-dropdown {
          border: 1px solid $clr-clearDK;
        }
      }
    }
    &.select2-container--disabled,
    &.select2-container--disabled:hover {
      background: $clr-clearDK !important;
      .select2-selection--single {
        background: $clr-clearDK !important;
      }
    }
  }
}
.select2-container {
  &.select2-container--default {
    &.select2-container--open {
      .select2-dropdown {
        border: 1px solid $clr-clearDK;
        -webkit-box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.5);
        -moz-box-shadow:    0px 6px 12px 0px rgba(0, 0, 0, 0.5);
        box-shadow:         0px 6px 12px 0px rgba(0, 0, 0, 0.5);
        .select2-search__field {
          color: $clr-carbon;
          border: 1px solid $clr-greyLT;
          border-radius: 0px;
          -webkit-box-shadow: none;
          box-shadow: none;
          -webkit-transition: all .15s ease-out;
          transition: all .15s ease-out;
          height: 38px;
          background-color: $clr-clear;
          &:focus {
            border-color: $clr-grey;
            -webkit-box-shadow: none;
            box-shadow: none;
          }
        }
        ul {
          li {
            font-size: 15px;
            color: $clr-carbon;
            &.select2-results__option--highlighted {
              background-color: $clr-primary;
              color: #FFF;
            }
          }
        }
      }
    }
  }
}

.form-control {
  font-size: 13px;
  color: $clr-carbon;
  background-color: $clr-clear;
  border: 1px solid $clr-greyLT;
  border-radius: 2px;
  box-shadow: none;
  transition: border-color ease-in-out .15s;
  &[readonly] {
    border-color: transparent;
  }
  &[disabled] {
    cursor:not-allowed;
    background-color: $clr-clearDK !important;
  }
  &:focus {
    color: $clr-carbon;
    background-color: #fff;
    border-color: $clr-primary;
    box-shadow: none;
  }
}
.form-horizontal {
  .form-group {
    margin-bottom: 25px !important;
    position: relative;
    .control-label {
      text-transform: uppercase;
      color: $clr-dark;
      text-align: left !important;
    }
    .form-control {
      height: 46px;
      padding: 12px 16px;
      line-height: 1.733333;
      font-size: 15px;
      height: 44px;
      margin: 0;
      padding: 0 20px;
      background: $clr-clear none repeat scroll 0 0;
      border: 1px solid  $clr-greyLT;
      border-radius: 0px;
      font-size: 16px;
      font-weight: 300;
      line-height: 44px;
      color: #333;
      -moz-border-radius: none;
      -webkit-border-radius: none;
      border-radius: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      position: relative;
    }
    .files {
      position: relative;
      span {
        position: absolute;
        top: -23px;
        right: 0;
      }
      .form-group {
        margin-bottom: 0 !important;
        #vista-previa {
          text-align: center;
          .thumbnail {
            width: 100%;
            margin: 0;
          }
        }
      }
    }
    label.error {
      position: absolute;
      right: 18px;
      bottom: 0;
      left: auto;
      text-align: center;
      z-index: 0;
    }
  }
}
.wizard {
  padding: 0 20px 20px;
  .wizard-inner {
    position: relative;
    .connecting-line {
      height: 4px;
      background: $clr-primaryLT;
      position: absolute;
      width: 66%;
      margin: 0 auto;
      left: 0;
      right: 0;
      top: 50%;
      z-index: 1;
    }
    .nav-tabs {
      position: relative;
      margin: 0 auto;
      margin-bottom: 0;
      border-bottom: none;
      li {
        width: 25%;
        &:after {
          content: " ";
          position: absolute;
          left: 46%;
          opacity: 0;
          margin: 0 auto;
          bottom: 0px;
          border: 5px solid transparent;
          border-bottom-color: $clr-primary;
          transition: 0.1s ease-in-out;
        }
        &.active {
          a, a:hover, a:focus {
            color: #555555;
            //cursor: default;
            border: 0;
            border-bottom-color: transparent;
            .round-tab {
              background: $clr-primary;
              border: 2px solid $clr-primary;
              i {
                color: #FFF;
              }
            }
          }
          &:after {
            content: " ";
            position: absolute;
            left: 46%;
            opacity: 1;
            margin: 0 auto;
            bottom: 0px;
            border: 10px solid transparent;
            //border-bottom-color: #5bc0de;
          }
        }
        a {
          width: 70px;
          height: 70px;
          margin: 20px auto;
          border-radius: 100%;
          padding: 0;
          &:hover {
            background: transparent;
          }
          .round-tab {
            width: 70px;
            height: 70px;
            line-height: 84px;
            display: inline-block;
            border-radius: 100px;
            background: #fff;
            border: 3px solid $clr-primary;
            z-index: 2;
            position: absolute;
            left: 0;
            text-align: center;
            font-size: 25px;
            &:hover {
              background: $clr-primaryLT;
              i {
                color: #FFF;
              }
            }
            i {
              color: $clr-primary;
              font-size: 36px;
              &.glyphicon {
                font-size: 32px;
                top: -3px;
              }
            }
          }
        }
      }
    }
  }
  form {
    .tab-content {
      .tab-pane {
        position: relative;
        padding-top: 20px;
        h3 {
          margin-top: 0;
          text-align: center;
          font-size: 20px;
          small {
            font-size: 16px;
            font-style: italic;
            top: -2px;
            position: relative;
          }
        }
        p {}
        ul {
          padding-top: 15px;
          margin-top: 25px;
          margin-right: -30px;
          margin-bottom: -30px;
          display: block;
          text-align: right;
          border-top: 1px solid rgba(0,0,0,0.05);
          li {
            .btn {
              &.prev-step {}
              &.skip-step {}
              &.next-step {}
            }
          }
        }
        &.active {
          h3 {}
          p {}
          ul {
            li {
              .btn {
                &.prev-step {}
                &.skip-step {}
                &.next-step {}
              }
            }
          }
        }
        .btn-addUser {
          height: 56px;
          width: 56px;
          background-color: $clr-primary;
          display: inline-block;
          border: none;
          border-radius: 50%;
          box-shadow: 0 0 4px rgba(0,0,0,0.14), 0 4px 8px rgba(0,0,0,0.28);
          outline: none;
          padding: 0;
          -webkit-user-drag: none;
          color: #FFF;
          margin-top: 20px;
          i {
            font-size: 22px;
            text-align: center;
            line-height: 46px;
            top: 4px;
            position: relative;
            width: 100%;
          }
        }
      }
    }
  }
}
.table-form-files {
  .files {
    .btn.btn-sm {
      padding: 8px 16px;
      font-size: 9px;
      float: left;
    }
    .form-group {
      margin: 0;
      float: right;
      margin-bottom: 0 !important;
      #vista-previa {
        position: relative;
        .thumbnail {
          margin: 0;
          background: transparent;
          border: none;
          margin-top: 2px !important;
          margin-bottom: 0 !important;
          i, img {
            display: none !important;
          }
          span {
            padding-bottom: 0 !important;
            padding-right: 80px !important;
          }
          .caption {
            position: absolute;
            top: 2px;
            right: 12px;
            padding-right: 60px;
            .removeFile {
              margin: 0;
              padding: 5px 8px;
              border: none;
              font-size: 8px;
              display: inline-block;
              width: 66px;
              position: absolute;
              top: 2px;
            }
          }
        }
        p {
          position: absolute;
          top: 0;
          right: 0;
          white-space: nowrap;
          margin-bottom: 0;
          font-size: 13px;
        }
      }
    }
  }
}
.floating-addUser {
  margin: 25px;
  position: fixed;
  position: fixed;
  bottom: 45px;
  right: 0;
  background-color: $clr-primary;
  display: inline-block;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  outline: none;
  padding: 0;
  -webkit-user-drag: none;
  font-weight: bold;
  color: #FFF;
  height: 56px;
  width: 56px;
  z-index: 20;
  i {
    position: absolute;
    font-size: 22px;
    text-align: center;
    line-height: 56px;
    width: 100%;
  }
  &:hover {
    color: #FFF;
    -webkit-animation: pulse 2s;
    animation: pulse 2s;
  }
}
.enfasis {
  font-size: 110%;
  text-transform: uppercase;
}
/*  BACKGROUNDS
========================== */
.no-bg {
  background: transparent;
}
.bg-primary {
  background-color: $clr-primary;
  &.lt { background-color: $clr-primaryLT }
  &.dk { background-color: $clr-primaryDK }
}
.bg-success {
  background-color: $clr-success;
  &.lt { background-color: $clr-successLT }
  &.dk { background-color: $clr-successDK }
}
.bg-warning {
  background-color: $clr-warning;
  &.lt { background-color: $clr-warningLT }
  &.dk { background-color: $clr-warningDK }
}
.bg-danger {
  background-color: $clr-danger;
  &.lt { background-color: $clr-dangerLT }
  &.dk { background-color: $clr-dangerDK }
}
.bg-info {
  background-color: $clr-mint;
  &.lt { background-color: $clr-mintLT }
  &.dk { background-color: $clr-mintDK }
}
.bg-gray {
  background-color: #F0F0F0;
  &.lt { background-color: #FFF }
  &.dk { background-color: #E8E8E8 }
}
.bg-dark {
  background-color: $clr-carbon;
  &.lt { background-color: $clr-carbonLT }
  &.dk { background-color: $clr-carbonDK }
}


/*  BUTTONS
========================== */
.btn {
  &.btn-default {
    background: $clr-greyLT;
    border-color: $clr-greyLT;
    &.btn-outline {
      background: transparent;
      border-radius: 0;
      color: $clr-greyLT !important;
      &:hover {
        color: #FFF !important;
      }
    }
    &:hover {
      background: $clr-grey !important;
      border-color: $clr-grey !important;
      color: #FFF !important;
    }
  }
  &.btn-primary {
    background: $clr-primary;
    border-color: $clr-primary;
    &.btn-outline {
      background: transparent;
      border-radius: 0;
      color: $clr-primary !important;
    }
  }
  &.btn-warning {
    background: $clr-warning;
    border-color: $clr-warning;
    &.btn-outline {
      background: transparent;
      border-radius: 0;
      color: $clr-warning !important;
    }
    &:hover {
      background: $clr-warningDK !important;
      border-color: $clr-warningDK !important;
      color: #FFF !important;
    }
  }
}
.btn-group {
  .btn {
    &.btn-sm {
      padding: 4px 10px 2px;
    }
    background: $clr-greyLT;
    &.btn-primary {
      background: $clr-primary;
      border-color: $clr-primary;
      &:hover {
        background: $clr-primaryDK;
        border-color: $clr-primaryDK;
      }
    }
    &.btn-success {
      background: $clr-success;
      border-color: $clr-success;
      &:hover {
        background: $clr-successDK;
        border-color: $clr-successDK;
      }
    }
    &.btn-warning {
      background: $clr-warning;
      border-color: $clr-warning;
      &:hover {
        background: $clr-warningDK;
        border-color: $clr-warningDK;
      }
    }
    &.btn-danger {
      background: $clr-danger;
      border-color: $clr-danger;
      &:hover {
        background: $clr-dangerDK;
        border-color: $clr-dangerDK;
      }
    }
  }
}

.multi-wrapper {
  .non-selected-wrapper,
  .selected-wrapper {
    height: 400px;
    .multibox-item.item {
      padding-left: 80px;
      position: relative;
      height: 74px;
      .img {
        position: absolute;
        left: 5px;
        width: 64px;
        height: 64px;
        border: 2px solid #FFF;
        display: block;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
      }
      span {
        font-size: 16px;
      }
      small {
        color: $clr-primary;
        font-size: 14px;
        display: block;
      }
    }
  }
  .non-selected-wrapper {
    .item.selected {
      display: none;
    }
  }
}

.toggle-vis {
  padding: 10px 12px 9px !important;
}


/*  LOGIN
========================== */
.login-register {
  text-align: center;
  background: url(../img/bg-8.jpg) center center/cover no-repeat !important;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  .overlay {
    background: $clr-carbon;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, $clr-carbon, $clr-carbonDK);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, $clr-carbon, $clr-carbonDK); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    opacity: 0.6;
  }
  .login-register-box {
    position: relative;
    .logo {
      font-size: 42px;
      color: rgba(255, 255, 255, 0.75);
      display: block;
      position: relative;
      margin: 5% auto -60px;
      @media screen and (max-width : 1679px) {
        margin: 25px auto -80px;
      }
      @media screen and (max-width : 767px) {
        margin: 25px auto -40px;
      }
      strong {
        color: #FFF;
      }
      img {
        max-width: 250px;
        @media screen and (max-width : 1679px) {
          max-width: 200px;
        }
        @media screen and (max-width : 767px) {
          max-width: 175px;
        }
      }
    }
    .form-wrapper {
      width: 420px;
      display: inline-block;
      padding: 80px 40px 30px;
      text-align: left;
      color: white;
      border-radius: 1rem;
      box-shadow: 0 10px 20px rgba(0,0,0,0.1);
      background: linear-gradient( to bottom, #313131, $clr-carbonDK );
      @media screen and (max-width : 1679px) {
        width: 400px;
        padding: 80px 40px 20px;
      }
      @media screen and (max-width : 767px) {
        width: 90%;
        margin: 0 5%;
        padding: 45px 30px 30px;
      }
      .form-header {
        h4 {
          font-size: 26px;
          font-weight: 700;
          margin-bottom: 30px;
          line-height: 1.12;
          @media screen and (max-width : 1679px) {
            font-size: 22px;
            margin-bottom: 20px;
          }
        }
      }
      .g-recaptcha {
        text-align: center;
        & > div {
          display: inline-block;
        }
      }
      .form-control {
        height: 44px;
        margin: 0;
        padding: 0 20px;
        background: #f8f8f8 none repeat scroll 0 0;
        border: 1px solid #eee;
        border-radius: 0px;
        font-size: 16px;
        font-weight: 300;
        line-height: 44px;
        color: #333;
        -moz-border-radius: none;
        -webkit-border-radius: none;
        border-radius: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        position: relative;
        &:focus {
          outline: 0;
          background: #f8f8f8 none repeat scroll 0 0;
          border: 1px solid #eee;
          -moz-box-shadow: none;
          -webkit-box-shadow: none;
          box-shadow: none;
        }
        & + label.error {
          bottom: 0;
          right: 10px;
        }
      }
      .checkbox {
        label {
          font-size: 16px;
          line-height: 17px;
        }
      }
      p {
        font-size: 15px;
        margin-bottom: 0;
      }
    }
  }

}

/*  LAYOUT
========================== */
.app {
  .app-aside {
    & > .left {
      background-color: $clr-dark;
      ul.nav {
        & > li {
          &.header {
            text-align: left;
            padding: 3px 15px;
            span {
              //font-size: 12px;
            }
          }
          & > a {
            span.nav-icon {
              i {
                font-size: 20px;
                margin: 5px;
                background: transparent;
                -o-transition: margin .3s ease;
                -webkit-transition: margin .3s ease;
                transition: margin .3 ease;
              }
            }
            span.nav-text {
              font-size: 13px !important;
              font-weight: 500;
              text-transform: uppercase;
              font-family: $font-montserrat;
              opacity: 1;
            }
            &:hover, &:focus {
              background-color: $clr-primary !important;
            }
          }
          ul.child_menu {
            background-color:  $clr-primary !important;
            li {
              a {
                font-family: $font-montserrat;
                font-size: 13px;
                text-transform: uppercase;
              }
            }
          }
          //&:hover:not([disabled]), &:focus:not([disabled]), &:active:not([disabled]) {
          &:not([disabled]):hover, &:not([disabled]):focus, &:not([disabled]):active {
            a{
              span.nav-icon {
                i {
                  margin-right: 15px;
                  background: rgba(0,0,0,0.15);
                }
              }
            }
          }
          &[disabled] {
            opacity: .5
          }
          &:hover {
            //background-color:  $clr-primary !important;
            a {
              //background-color:  $clr-primary !important;
            }
          }
        }
      }
      .llb-logo {
        height: 130px;
        position: absolute;
        bottom: 70px;
        left: 0;
        right: 0;
        background-image: url(../../assets/img/llb-logo.png);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        @media screen and (max-width : 1679px) {
          display: none;
        }
      }
    }
  }
  .app-content {
    .app-header {
      .navbar {
        background-color: $clr-dark;
        .menu-toggle {
          background-color: $clr-darkDK;
          .icon-list-layout__inner{display:block;width:18px;height:18px;display:block;position:absolute;left:50%;top:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);text-align:center;}
          .icon-list-layout__item{display:block;position:relative;width:4px;height:4px;font-size:0;line-height:0;background-color: #FFF;margin:0 0 3px 0;-webkit-transition:all 0.5s ease;transition:all 0.5s ease;}
          .icon-list-layout__item::before{content:"";display:block;position:absolute;width:11px;height:4px;background-color: #FFF;left:7px;-webkit-transition:all 0.5s ease-in-out;transition:all 0.5s ease-in-out;}
          .icon-list-layout--active .icon-list-layout__item{background-color: #FFF;}
          .icon-list-layout--active .icon-list-layout__item::before{background-color: #FFF;}
          &:hover .icon-list-layout__item{-webkit-animation:icon-list-layout 0.5s;animation:icon-list-layout 0.5s;background-color: #FFF;}
          &:hover .icon-list-layout__item::before{background-color: #FFF;-webkit-animation:icon-list-layout-before 0.5s;animation:icon-list-layout-before 0.5s;}
          &:hover .icon-list-layout__item:nth-child(2){-webkit-animation-delay:0.1s;animation-delay:0.1s;}
          &:hover .icon-list-layout__item:nth-child(2)::before{-webkit-animation-delay:0.1s;animation-delay:0.1s;}
          &:hover .icon-list-layout__item:nth-child(3){-webkit-animation-delay:0.2s;animation-delay:0.2s;}
          &:hover .icon-list-layout__item:nth-child(3)::before{-webkit-animation-delay:0.2s;animation-delay:0.2s;}
          @-webkit-keyframes icon-list-layout{50%{-webkit-transform:translateX(-3px);transform:translateX(-3px);}}
          @keyframes icon-list-layout{50%{-webkit-transform:translateX(-3px);transform:translateX(-3px);}}
          @-webkit-keyframes icon-list-layout-before{50%{-webkit-transform:translateX(6px);transform:translateX(6px);}}
          @keyframes icon-list-layout-before{50%{-webkit-transform:translateX(6px);transform:translateX(6px);}}

          .icon-grid-layout__inner{display:block;width:18px;height:18px;display:block;position:absolute;left:50%;top:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);text-align:center;}
          .icon-grid-layout__item{display:block;position:relative;width:4px;height:4px;font-size:0;line-height:0;background-color: #FFF;margin:0 auto 3px auto;-webkit-transition:all 0.5s ease;transition:all 0.5s ease;}
          .icon-grid-layout__item::before, .icon-grid-layout__item::after{content:"";display:block;position:absolute;width:4px;height:4px;background-color: #FFF;-webkit-transition:all 0.5s ease;transition:all 0.5s ease;}
          .icon-grid-layout__item::before{left:-7px;}
          .icon-grid-layout__item::after{left:7px;}
          .icon-grid-layout--active .icon-grid-layout__item{background-color: #FFF;}
          .icon-grid-layout--active .icon-grid-layout__item::before, .icon-grid-layout--active .icon-grid-layout__item::after{background-color: #FFF;}
          &:hover .icon-grid-layout__item{background-color: #FFF;}
          &:hover .icon-grid-layout__item::before, .icon-grid-layout:hover .icon-grid-layout__item::after{background-color: #FFF;}
          &:hover .icon-grid-layout__item:first-child{-webkit-animation:icon-grid-layout-1 .5s;animation:icon-grid-layout-1 .5s;}
          &:hover .icon-grid-layout__item:first-child::before{-webkit-animation:icon-grid-layout-1-before .5s;animation:icon-grid-layout-1-before .5s;}
          &:hover .icon-grid-layout__item:first-child::after{-webkit-animation:icon-grid-layout-1-after .5s;animation:icon-grid-layout-1-after .5s;}
          &:hover .icon-grid-layout__item:nth-child(2)::before{-webkit-animation:icon-grid-layout-2-before .5s;animation:icon-grid-layout-2-before .5s;}
          &:hover .icon-grid-layout__item:nth-child(2)::after{-webkit-animation:icon-grid-layout-2-after .5s;animation:icon-grid-layout-2-after .5s;}
          &:hover .icon-grid-layout__item:nth-child(3){-webkit-animation:icon-grid-layout-3 .5s;animation:icon-grid-layout-3 .5s;}
          &:hover .icon-grid-layout__item:nth-child(3)::before{-webkit-animation:icon-grid-layout-3-before .5s;animation:icon-grid-layout-3-before .5s;}
          &:hover .icon-grid-layout__item:nth-child(3)::after{-webkit-animation:icon-grid-layout-3-after .5s;animation:icon-grid-layout-3-after .5s;}
          @-webkit-keyframes icon-grid-layout-1{50%{-webkit-transform:translateY(-2px);transform:translateY(-2px);}}
          @keyframes icon-grid-layout-1{50%{-webkit-transform:translateY(-2px);transform:translateY(-2px);}}
          @-webkit-keyframes icon-grid-layout-1-before{50%{-webkit-transform:translateX(-2px);transform:translateX(-2px);}}
          @keyframes icon-grid-layout-1-before{50%{-webkit-transform:translateX(-2px);transform:translateX(-2px);}}
          @-webkit-keyframes icon-grid-layout-1-after{50%{-webkit-transform:translateX(2px);transform:translateX(2px);}}
          @keyframes icon-grid-layout-1-after{50%{-webkit-transform:translateX(2px);transform:translateX(2px);}}
          @-webkit-keyframes icon-grid-layout-2-before{50%{-webkit-transform:translateX(-2px);transform:translateX(-2px);}}
          @keyframes icon-grid-layout-2-before{50%{-webkit-transform:translateX(-2px);transform:translateX(-2px);}}
          @-webkit-keyframes icon-grid-layout-2-after{50%{-webkit-transform:translateX(2px);transform:translateX(2px);}}
          @keyframes icon-grid-layout-2-after{50%{-webkit-transform:translateX(2px);transform:translateX(2px);}}
          @-webkit-keyframes icon-grid-layout-3{50%{-webkit-transform:translateY(2px);transform:translateY(2px);}}
          @keyframes icon-grid-layout-3{50%{-webkit-transform:translateY(2px);transform:translateY(2px);}}
          @-webkit-keyframes icon-grid-layout-3-before{50%{-webkit-transform:translateX(-2px);transform:translateX(-2px);}}
          @keyframes icon-grid-layout-3-before{50%{-webkit-transform:translateX(-2px);transform:translateX(-2px);}}
          @-webkit-keyframes icon-grid-layout-3-after{50%{-webkit-transform:translateX(2px);transform:translateX(2px);}}
          @keyframes icon-grid-layout-3-after{50%{-webkit-transform:translateX(2px);transform:translateX(2px);}}
          &:hover {
            background-color: $clr-primary;
          }
        }
        #pageTitle {
          color: #fff !important;
          font-family: $font-montserrat;
          small {
            color: rgba(255, 255, 255, 0.75);
            margin-left: 5px;
            @media screen and (max-width : 767px) {
              display: none;
            }
          }
        }
        li {
          &.open {
            & > a {
              background: $clr-primary;
              .label {
                background-color: $clr-danger;
                color: #FFF;
              }
            }
          }
          & > a {
            color: #FFF;
            font-size: 14px;
            line-height: 16px;
            text-align: right;
            padding: 16px 18px 8px !important;
            &:hover, &:focus, &:active {
              background: $clr-primaryDK;
            }
            .label {
              background-color: $clr-danger !important;
              color: #FFF !important;
              line-height: 20px;
            }
            small {
              font-size: 12px;
              color: #FFF;
              color: rgba(255, 255, 255, 0.75);
              @media screen and (max-width : 767px) {
                display: none !important;
              }
            }
            i {
              margin-top: -4px;
            }
            &.user-profile {
              padding-right: 40px !important;
              @media screen and (max-width : 767px) {
                padding: 20px 18px !important;
                max-width: 120px;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
          a {
            &:hover, &:focus, &:active {
              //background-color: transparent;

            }
          }

          ul.app-user-menu {
            background-color: $clr-darkLT;
            li {
              &.user-panel {
                background: linear-gradient(135deg, $clr-primary, $clr-primaryDK);
                .info {
                  a {
                    color: #fff;
                    text-decoration: none;
                    padding-right: 5px;
                    margin-top: 3px;
                    font-size: 12px;
                  }
                  p {
                    font-weight: 600;
                    margin-bottom: 9px;
                  }
                  .lblName {
                    font-size: 15px;
                    font-weight: 500;
                  }
                  .lblLocation {
                    font-size: 12px;
                  }
                }
                &:after {
                  clear: both;
                }
              }
              &.header {
                background-color: $clr-darkDK;
                text-align: left;
                padding: 3px 15px;
                span {
                  //font-size: 12px;
                }
              }
              a {
                color: rgba(255,255,255,0.85) !important;
                text-align: left;
                padding: 12px 20px !important;
                &:hover, &:active, &:focus {
                  color: #FFF !important;
                  background-color: rgba(255,255,255,0.1);
                }
                i {
                  color: #FFF !important;
                  background-color: rgba(255,255,255,0.15);
                  position: absolute;
                  right: 0;
                  width: 40px;
                  height: 40px;
                  margin: 0;
                  padding: 0;
                  font-size: 20px;
                  text-align: center;
                  top: 0;
                }
              }
              &.disabled {
                a {
                  opacity: .6;
                  pointer-events: none;
                }
              }
            }
          }
          .notifications {}
        }
      }
    }
    .app-body {
      @media screen and (max-width : 767px) {
        //text-align: center;
      }
      .box {
        // height: 100%;
        display: block;
        border-bottom: none;
        border-radius: 0;
        margin-bottom: 15px;
        box-shadow: 0 4px 3px -2px rgba(0,0,0,0.15);
        h5 {
          padding: 16px;
          margin: 0;
          font-size: 18px;
          line-height: 1;
        }
        .form-group {
          margin: 0;
          @media screen and (max-width : 767px) {
            float: none !important;
            margin-left: 0 !important;
          }
        }
        .box-header {
          @media screen and (max-width : 767px) {
            padding: 25px;
            text-align: center;
          }
          &.page-header {
            margin: 0;
            background-image: url(../img/bg-card.jpg);
            background-size: cover;
            background-position: center 400%;
            background-attachment: fixed;
            position: relative;
            border: none;
            height: auto;
            display: flex;
            text-align: center;
            padding: 8rem 0 14rem;
            // margin-bottom: -5rem;
            @media screen and (max-width : 767px) {
              height: auto;
            }
            .overlay {
              background: linear-gradient(135deg, $clr-primary, $clr-primaryDK);
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              opacity: .75;
              &:after {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background-image: url(../img/page-heading-pattern.png);
                background-size: auto;
                background-position: 100% 0;
                background-repeat: no-repeat;
                opacity: 0.4;
              }
            }
            .form-control {
              width: 170px;
              height: 34px;
              padding: 5px 15px;
              margin-top: -12px;
              border-radius: 0;
              box-sizing: border-box;
              position: relative;
              display: inline-block;
              background: transparent;
              font-size: 13px;
              font-weight: 500;
              text-transform: uppercase;
              border-color: #fff;
              color: #FFF;
              &:focus {
                background: rgba(255, 255, 255, 0.25);
              }
              &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: #FFF;
              }
              &::-moz-placeholder { /* Firefox 19+ */
                color: #FFF;
              }
              &:-ms-input-placeholder { /* IE 10+ */
                color: #FFF;
              }
              &:-moz-placeholder { /* Firefox 18- */
                color: #FFF;
              }
            }
            label.control-label {
              color: #FFF;
              font-weight: 400;
              margin-right: 5px;
            }
            select.form-control {
              margin-top: -5px;
              option {
                color: $clr-carbon
              }
            }
            .toggleCards {
              width: 34px;
              height: 34px;
              padding: 0;
              display: none;
              i {
                font-size: 20px !important;
                line-height: 30px;
              }
            }
            .btn.btn-outline {
              margin-top: 0px;
            }
          }
          a {
            color: #FFF;
            i {
              float: left;
              transition: transform .3s ease;
            }
            span {
              display: inline-block;
              float: left;
              line-height: 24px;
              font-size: 15px;
              margin-left: 5px;
            }
            &:hover {
              i {
                transform: translateX(-5px);
              }
            }
          }
          h3 {
            .italic {
              color: $clr-primary;
            }
          }
          h4 {
            font-family: $font-montserrat;
            position: relative;
            text-transform: uppercase;
            color: #fff;
            font-weight: 700;
            font-size: 36px;
            margin-bottom: 0;
            font-style: normal;
            text-shadow: 0 2px 0 rgba(0,0,0,.25);
            text-align: center;
            small {
              color: #fff;
              display: block;
              margin: 0;
              @media screen and (max-width : 767px) {
                display: none;
              }
            }
          }
          .filter {
            .btn {
              &.btn-outline {
                border-color: #FFF;
                color: #FFF !important;
                &:hover {
                  background: #FFF;
                  color: $clr-dark !important;
                }
              }
            }
          }
          &::before, &::after {
            width: 0;
            background: transparent;
          }
          .btn.btn-outline {
            //margin-top: 4px;
            top: 0;
            @media screen and (max-width : 767px) {
              margin-bottom: 10px;
            }
          }
          label {
            font-weight: 400;
            margin-right: 5px;
          }
        }
        .box-tool {
          & > .form-inline {
            position: relative;
            top: -5px;
            .form-control {
              //border: none;
            }
          }
          & > .nav {
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;
            .nav-item {
              border: inherit;
              .nav-link, .nav-tabs {
                position: relative;
                display: inline-block;
                padding: 0;
                &:hover {
                  background: transparent;
                }
                i {
                  font-size: 18px;
                }
              }
            }
          }
        }
        .box-body {
          padding: 0;
          h4.title {
            font-size: 16px;
            text-align: center;
          }
          .muestrameses {
            font-weight: 700;
            text-transform: uppercase;
          }
          div.dataTables_wrapper {
            .dt-buttons, .dataTables_filter {
              display: none;
            }
            .dataTables_info {
              padding: 10px 25px;
            }
            .mdl-grid {
              &:first-child {
                display: none;
                .mdl-cell {
                  margin: 0 !important;
                  label {
                    margin-bottom: 0;
                  }
                }
              }
              .mdl-cell--6-col {
                width: 50% !important;
              }
              &.dt-table {
                padding: 0;
                .mdl-cell--12-col {
                  margin: 0;
                  width: 100%;
                  .table-condensed > thead > tr > th,
                  .table-condensed > tbody > tr > th,
                  .table-condensed > tfoot > tr > th,
                  .table-condensed > thead > tr > td,
                  .table-condensed > tbody > tr > td,
                  .table-condensed > tfoot > tr > td {
                    padding: 10px 15px;
                  }
                }
                .btn-group {
                  margin: -5px 0;
                }
              }
            }
            h4 {
              margin: 0;
              font-size: 14px;
              text-align: center;
              font-weight: 700;
              white-space: nowrap;
              line-height: 18px;
            }
            .mdl-button--raised.mdl-button--colored {
              font-family: $font-montserrat;
              box-shadow: none !important;
              background-color: $clr-primary;
              &:hover {
                background-color: $clr-primaryDK;
              }
            }
          }
          .vista-impresion {
            width: 21cm;
            padding: 0 2cm !important;
            margin: 0 auto;
            border: 1px #D3D3D3 solid;
            border-radius: 1px;
            background: white;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
            font-family: Arial, Helvetica, sans-serif !important;
            h4, p, span {
              font-family: Arial, Helvetica, sans-serif !important;
            }
            & > .title {
              font-size: 20px;
            }
            p, span {
              font-size: 12pt;
            }
          }
          table:not(.cards) {
            .imagen {
              .img {
                  width: 100%;
                  height: 28px;
                  display: block;
                  background-size: contain;
                  background-repeat: no-repeat;
                  background-position: center;
              }
            }
          }
          table.cards {
            width: 100% !important;
            background-color: transparent;
            tbody {
              tr {
                float: left;
                background-color: transparent !important;
                margin: 20px;
                .card {
                  padding: 20px;
                  background-color: #FFF !important;
                  &.b-r {
                    border-right: none;
                    -webkit-box-shadow: inset -1px 0px 0px 0px rgba(120, 130, 140, 0.13);
                    -moz-box-shadow: inset -1px 0px 0px 0px rgba(120, 130, 140, 0.13);
                    box-shadow: inset -1px 0px 0px 0px rgba(120, 130, 140, 0.13);
                  }
                  td {
                    display: block;
                    width: 100%;
                    overflow: hidden;
                    text-align: left;
                    padding: 4px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    &:first-child {
                      border-top: none;
                    }
                    &.id {
                      display: none;
                    }
                    &.imagen {
                      width: calc(100% + 41px);
                      height: 130px;
                      padding: 20px;
                      margin: -20px -20px 10px;
                      display: table;
                      background-color: $clr-darkLT;
                      position: relative;
                      &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        background-image: url(../img/page-heading-pattern.png);
                        background-size: auto;
                        background-position: 100% 0;
                        background-repeat: no-repeat;
                        opacity: 0.4;
                      }
                      label {
                        display: none;
                      }
                      h4 {
                        display: block;
                        padding: 0 10px;
                        text-align: center;
                        text-transform: uppercase;
                        font-weight: 700;
                        font-size: 26px;
                        text-shadow: 0px 2px 4px rgba(0,0,0,0.5);
                        display: table-cell;
                        vertical-align: middle;
                        white-space: normal;
                        z-index: 9;
                        position: relative;
                        color: #FFF;
                      }
                      .img {
                        width: 100%;
                        height: 80px;
                        margin-top: 20px;
                        display: block;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;
                        z-index: 9;
                        position: relative;
                        filter: brightness(10000%);
                      }
                    }
                    &.botones {
                      text-align: right;
                      margin-top: 20px;
                      padding-top: 15px;
                      border-top: 1px solid rgba(0,0,0,0.15);
                      label {
                        display: none;
                      }
                      .btn.btn-icon {
                        width: 60px;
                        margin: 0 !important;
                        border-radius: 0 !important;
                      }
                    }
                    &:nth-child(even) {
                      background: #eee;
                    }
                    &:last-child {
                      background: #fff;
                    }
                  }
                  /*&::before {
                    content: "";
                    display: block;
                    position: absolute;
                    height: 6px;
                    left: 9px;
                    right: 9px;
                    top: 0;
                    background-color: $clr-azul;
                    border-radius: 3px 3px 0 0;
                  }*/
                }
              }
              img {
                height: 100px;
              }
              label {
                margin-right: 5px;
                width: 45%;
                display: inline;
                position: relative;
                font-size: 75%;
                margin: 0;
                float: left;
                color: $clr-carbon;
                text-transform: uppercase;
                font-weight: 700;
                line-height: 20px;
              }
            }
          }

          .dt-buttons {
            display: inline-block;
            .btn {
              margin: 0;
              padding: 6px 20px !important;
              padding-left: 40px !important;
              i {
                width: 30px;
                height: 30px;
                left: 0px;
                top: 0px;
                line-height: 30px;
                border-radius: 1px;
              }
            }
          }
          p {
            font-size: 15px;
            a {
              font-weight: 700;
            }
          }
        }
        .box-footer {
          padding: 0;
          margin: 0 -10px -25px;
          button {
            padding: 15px;
          }
        }
        &.addedit {
          background-image: url(../img/bg-card.jpg);
          background-size: cover;
          .overlay {
            background-color: transparentize($clr-primaryDK, .25);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            &:after {
              content: "";
              display: block;
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              background-image: url(../img/page-heading-pattern.png);
              background-size: auto;
              background-position: 100% 0;
              background-repeat: no-repeat;
              opacity: 0.4;
            }
          }
          .page-header {
            background: none;
            .overlay {
              background: none;
              &:after {
                background: none;
              }
            }
          }
          .box-body {
            padding-bottom: 20px;
          }
        }
        &.contenido {
          margin-top: -120px;
          border-radius: 4px;
          //overflow: hidden;
          @media screen and (max-width : 767px) {
            margin-top: 0;
          }
          &.info-general {
            margin-right: -45px;
            .logo {
              width: 100%;
              margin-bottom: 30px;
            }
            h3 {
              font-size: 20px;
              margin: 0;
            }
            ul.detalles {
              li {
                font-size: 14px;
                .display-block {
                  font-size: 10px;
                  font-weight: 600;
                  text-transform: uppercase;
                  letter-spacing: 1px;
                  margin-top: 10px;
                }
              }
            }
            hr {
              margin: 20px 0;
            }
          }
        }
      }
    }
    .app-footer {
      background: #FFF !important;
      color: #777;
      .foot-container {
        color: $clr-carbon;
        font-family: $font-montserrat;
        .text-muted {
          color: $clr-carbon !important;
        }
      }
    }
  }
}

body {
  &.right-menu-open {
    a {
      &.user-profile {
        background: $clr-primaryDK;
      }
    }
  }
}

.side-banner {
  width: 100%;
  height: 0;
  display: block;
  background-image: url(../img/bg-login2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 0;
  -webkit-transition: height .15s linear;
  -moz-transition: height .15s linear;
  -o-transition: height .15s linear;
  transition: height .15s linear;
  //box-shadow: 0 2px 2px rgba(0,0,0,0.03), 0 1px 0 rgba(0,0,0,0.03);
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparentize($clr-primaryDK, .25);
    pointer-events: none;
    //box-shadow: 0 2px 2px rgba(0,0,0,0.03), 0 1px 0 rgba(0,0,0,0.03);
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url(../img/page-heading-pattern.png);
    background-size: auto;
    background-position: 100% 0;
    background-repeat: no-repeat;
    opacity: 0.4;
    //box-shadow: 0 2px 2px rgba(0,0,0,0.03), 0 1px 0 rgba(0,0,0,0.03);
  }
  h4 {
    color: #fff;
    padding: 0;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 40px;
    text-shadow: 2px 0px 4px rgba(0, 0, 0, 0.5);
    white-space: normal;
    z-index: 9;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(-270deg);
    -moz-transform: translateX(-50%) translateY(-50%) rotate(-270deg);
    -ms-transform: translateX(-50%) translateY(-50%) rotate(-270deg);
    -o-transform: translateX(-50%) translateY(-50%) rotate(-270deg);
    transform:  translateX(-50%) translateY(-50%) rotate(-270deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  }
}


/*  INICIO
========================== */
.tiles-inicio {
  .box {
    color: #fff;
    .numerote {
      width: auto;
      line-height: 34px;
      margin-right: 10px !important;
      letter-spacing: -4px;
      font-weight: 800;
      font-size: 50px;
      background-color: transparent;
      text-shadow: 1px 1px 2px rgba(0,0,0,0.25);
    }
    .text-md {
      font-size: 16px;
      text-transform: uppercase;
      text-shadow: 1px 1px 2px rgba(0,0,0,0.25);
      margin-bottom: -12px !important;
    }
  }
}
.leyenda {
  ul {
    margin-bottom: 0;
    li {
      font-size: 10px;
      font-weight: 500;
      line-height: 22px;
      &.header {
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
        i {
          width: 12px;
          height: 12px;
          margin-top: 5px;
          float: right;
          display: block;
          border-radius: 50%;
        }
        &.r1 i { background-color: $clr-primaryDK; }
        &.r2 i { background-color: $clr-violetLT; }
        &.r3 i { background-color: $clr-primary; }
        &.r4 i { background-color: $clr-success; }
        &.r5 i { background-color: $clr-orange; }
        &.r6 i { background-color: $clr-danger; }
        &.r7 i { background-color: $clr-warning; }
      }
    }
  }
}
#chaptersMap {
  text-align: center;
  position: relative;
  & > svg {
    //height: 100%; width: 100%;
    width: 650px !important;
    height: 450px !important;
    path {
      cursor: pointer;
      &:hover {
        //fill: #FFF;
      }
    }
  }
  .mapaTitle {
    position: absolute;
    top: 0;
    right:0;
    text-align: right;
    h4 {
      margin: 0;
      font-size: 18px;
      font-weight: 700;
    }
  }
}
.news {
  max-height: 399px;
  li {
    a {
      margin-bottom: 10px;
      color: #000;
      text-transform: uppercase;
      font-size: 14px;
      display: block;
      &:hover {
        color: $clr-primary;
      }
    }
    span {
      display: block;
      text-align: right;
      color: rgba(0,0,0,.5);
      i {
        font-size: 14px;
        position: relative;
        top: 2px;
      }
    }
    &:not(:last-child) {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid rgba(120, 130, 140, .13)
    }
  }
}
.app-body {
  .infoSection {
    .infoRow {
      .infoTile {
        overflow: hidden;
        color: #FFF;
        h4 {
          font-size: 20px;
          font-weight: 600;
          margin-top: 22px;
          text-shadow: 1px 1px 2px rgba(0,0,0,0.25);
        }
        i {
          position: absolute;
          bottom: -30px;
          right: -30px;
          font-size: 200px;
          color: rgba(0,0,0,0.15);
        }
        small {
          position: absolute;
          bottom: 5px;
          left: 25px;
        }
      }
    }
    &:nth-child(3) > .infoRow > .infoTile { background-color: $clr-primary; }
    &:nth-child(4) > .infoRow > .infoTile { background-color: $clr-warning; }
    &:nth-child(5) > .infoRow > .infoTile { background-color: $clr-danger; }
    &:nth-child(6) > .infoRow > .infoTile { background-color: $clr-success; }
    &:nth-child(7) > .infoRow > .infoTile { background-color: $clr-orange; }
    &:nth-child(8) > .infoRow > .infoTile { background-color: $clr-mint; }
    &:nth-child(9) > .infoRow > .infoTile { background-color: $clr-primaryLT; }
    &:nth-child(10) > .infoRow > .infoTile { background-color: $clr-warningLT; }
    &:nth-child(11) > .infoRow > .infoTile { background-color: $clr-dangerLT; }
    &:nth-child(12) > .infoRow > .infoTile { background-color: $clr-successLT; }
  }
}

/*  POST
========================== */
.post {
  &.news-post {
    padding-top: 110px;
  }
  .page-heading {
    background-color: $clr-dark;
    background-image: url(../img/banner-2.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 90%;
    background-attachment: fixed;
    text-align: center;
    padding: 50px 0 100px;
    position: relative;
    @media (min-width: 992px) {
      .page-heading {
        padding: 110px 0 106px;
      }
    }
    .overlay {
      position: absolute;
      background: linear-gradient(135deg, $clr-greenLPB 49.999%, $clr-greenLPBDK 50%);
      opacity: 0.4;
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-image: url(../img/page-heading-pattern.png);
        background-size: auto;
        background-position: 100% 0;
        background-repeat: no-repeat;
        opacity: 0.4;
      }
    }
    .post-meta {
      padding-bottom: 45px;
      .page-heading-title {
        // text-transform: uppercase;
        // color: #fff;
        // letter-spacing: -0.02em;
        // font-size: 24px;
        // font-weight: 700;
        // margin-bottom: 0;
        // font-style: normal;
        // line-height: 1em;
        // margin-bottom: 0.475em;
        position: relative;
        text-transform: uppercase;
        color: #fff;
        font-weight: 700;
        font-size: 36px;
        margin-bottom: 0;
        font-style: normal;
        text-shadow: 0 2px 0 rgba(0,0,0,.25);
      }
      @media (min-width: 992px) {
        .page-heading-title {
          font-size: 42px;
        }
      }
      .page-heading-subtitle {
        margin-bottom: 0;
        color: #FFF;
        letter-spacing: 0;
        font-size: 16px;
        margin-top: 1rem;
        font-weight: 600;
      }
      .post-meta-items {
        color: #fff;
        margin-bottom: 22px;
        li {
          text-transform: uppercase;
          line-height: 1.2em;
          display: inline-block;
          vertical-align: middle;
          &.meta-date {
            font-size: 10px;
          }
          @media (min-width: 992px) {
            &.meta-date {
              font-size: 12px;
            }
          }
        }
      }
      .post-author {
        text-align: center;
        .post-author-avatar {
          display: block;
          width: 40px;
          height: 40px;
          margin: 0 auto 10px auto;
          overflow: hidden;
          border-radius: 50%;
          img {
            max-width: 100%;
            height: auto;
            display: block;
          }
        }
        .post-author-info {
          position: relative;
          display: inline-block;
          vertical-align: middle;
          .post-author-name {
            font-size: 13px;
            font-weight: 700;
            letter-spacing: -0.02em;
            color: #fff;
            margin: 0;
            margin-bottom: .2em;
          }
        }
      }
    }
  }
  .site-content {
    padding: 30px 0;
    .content {
      .post-single {
        border: 1px solid rgba(255, 255, 255, 0.05);
        border-radius: 0.5rem;
        background-color: #FFF;
        margin-bottom: 15px;
        margin-top: -130px;
        // box-shadow: 0 2px 2px rgba(0,0,0,0.03), 0 1px 0 rgba(0,0,0,0.03);
        box-shadow: 0 4px 16px rgba(0,0,0,.15);
        overflow: hidden;
        .card-content {
          padding: 24px 24px;
          padding-top: 0 !important;
          .post-content {
            .post-image {
              width: calc(100% + 50px);
              height: 400px;
              background-position: center;
              background-size: cover;
              margin: -1px -25px 0;
            }
            p {
              color: $clr-carbon;
              margin: 0 0 1.5em;
              font-size: 16px;
              /*&:first-child {
                &::first-letter {
                  margin-right: 5px;
                  color: $clr-carbonLT;
                  font-size: 200%;
                  font-weight: 700;
                  text-transform: uppercase;
                }
              }*/
            }
          }
        }
        .card-head {
          font-size: 14px;
          text-align: center;
          letter-spacing: 0;
        }
      }
      .mini-menu {
        .welcome-header {
          h4 {
            font-size: 20px;
            letter-spacing: -1px;
            padding-bottom: 15px;
            margin-top: 60px;
            margin-bottom: 30px;
            position: relative;
            border-bottom: 1px solid rgba(0, 0, 0, .05);
            color: $clr-carbon;
            span {
              color: $clr-primary;
            }
          }
        }
        .paso_box {
          margin: 60px 0 20px;
          padding: 40px 20px;
          position: relative;
          min-height: 200px;
          border-radius: 20px;
          background-color: #fff;
          border: 1px solid $clr-greyLT;
          display: block;
          .block {
            display: block;
            clear: both;
            position: relative;
            margin-top: -110px;
          }
          &:hover .hovicon {
            color: #fff;
            background: $clr-primary;
            i {
              color: #fff;
            }
            &:after {
              -webkit-transform: scale(1);
              -moz-transform: scale(1);
              -ms-transform: scale(1);
              transform: scale(1);
              opacity: 1;
            }
          }
          .hovicon {
            background: #FFF;
            border: 3px solid $clr-primary;
            color: $clr-primary;
          }
          span {
            display: block;
            line-height: 90px;
            text-align: center;
            color: $clr-carbonDK;
            font-weight: 600;
            font-size: 130px;
            position: absolute;
            bottom: 8px;
            left: 18px;
          }
          h3 {
            font-size: 18px;
            text-align: center;
            margin: 10px 0;
            line-height: 24px;
          }
          p {
            font-size: 14px;
            font-weight: 400;
            color: $clr-carbon;
            margin: 7px 0 10px;
            line-height: 20px;
            text-align: center;
            &.bottom small {
              line-height: 17px;
              font-size: 12px;
              font-style: italic;
              text-align: left;
              position: absolute;
              bottom: 10px;
              left: 20px;
              right: 20px;
            }
          }
        }

      }
    }
  }
  @media (min-width: 992px) {
    .site-content {
      padding: 60px 0;
      .post-single {
        margin-top: -160px;
        .card-content {
          padding: 43px 43px;
        }
      }
    }
  }
}

.thumbnail {
  display: inline-block;
  padding: 4px;
  height:auto;
  margin-right: 20px;
  margin-bottom: 20px;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: border .2s ease-in-out;
  -o-transition: border .2s ease-in-out;
  transition: border .2s ease-in-out;
}
.thumbnail .caption {
  padding: 9px;
  color: #333;
}
.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-default {
  color: #545454;
  background-color: #f5f5f5;
  border-color: #e9e9e9;
}
.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}
.error{
  color:red;
}
.etiqueta {
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid #9f9d9d;
}
.noinput {
  background-color:transparent;
  border: 0px solid;
  height:40px;
  width:260px;
  width: 100%;
}
.noinput:focus {
  outline:none;
}
.capturable{
  color: #646464;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all .15s ease-out;
  transition: all .15s ease-out;
}
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  background-color: #fff;
  opacity: 1;
}

//  AFFIDAVIT
.affidavit-info {
  background: #FFF;
  padding: 15px;
  margin: 0;
  margin-right: -50px;
  .league-image {
    width: 100%;
  }
  h3 {
    padding: 0 5px;
    margin: 24px 0 15px;
    line-height: 15px;
    font-size: 15px;
  }
  .detalles {
    margin-top: 10px;
    padding: 0 5px;
    list-style: none;
    li {
      position: relative;
      margin: 0 0 10px 0;
      padding-left: 10px;
      color: $clr-carbon;
      .display-block {
        font-size: 10px;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $clr-grey;
      }
    }
  }
}
.tab-content {
  .box {
    .fadeInRight {
      -webkit-animation: fadeInRight .5s; /* Safari 4+ */
      -moz-animation:    fadeInRight .5s; /* Fx 5+ */
      -o-animation:      fadeInRight .5s; /* Opera 12+ */
      animation:         fadeInRight .5s; /* IE 10+, Fx 29+ */
    }
  }
}
/*   HOVICONS
======================================*/


.hovicon {
  display: inline-block;
  font-size: 45px;
  line-height: 100px;
  cursor: pointer;
  margin: 20px 20px 10px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border:3px solid #fff;
  text-align: center;
  position: relative;
  text-decoration: none;
  z-index: 1;
  color: #fff;
  b {
    font-size: 45px;
    margin-top: 24px;
    //text-shadow: longshadow(darken($clr-primary, 20%), $clr-primary, 10, 0.5);
  }
  &:after {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    content: '';
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
  &:before {
    speak: none;
    font-size: 48px;
    line-height: 90px;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    display: block;
    -webkit-font-smoothing: antialiased;
  }
  &.effect-1 {
    background: $clr-primary;
    -webkit-transition: background 0.2s, color 0.2s;
    -moz-transition: background 0.2s, color 0.2s;
    transition: background 0.2s, color 0.2s;
    &:after {
      top: -7px;
      left: -7px;
      padding: 7px;
      box-shadow: 0 0 0 4px $clr-primary;
      -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
      -webkit-transform: scale(0.8);
      -moz-transition: -moz-transform 0.2s, opacity 0.2s;
      -moz-transform: scale(0.8);
      -ms-transform: scale(0.8);
      transition: transform 0.2s, opacity 0.2s;
      transform: scale(0.8);
      opacity: 0;
    }
  }
}
/*  VIEW PERFIL
============================================ */
.view-jugador-perfil {
  .view-header {
    background-image: url(../img/bg-login.jpg);
    background-size: cover;
    background-position: center bottom;
    background-attachment: fixed;
    position: relative;
    overflow: hidden;
    text-align: center;
    padding: 40px 0 150px;
    .overlay {
      background: linear-gradient(135deg, $clr-primary, $clr-primaryDK);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0.85;
      overflow: hidden;
      &::after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        background-image: url(../img/page-heading-pattern.png);
        background-size: auto;
        background-position: 100% 0;
        background-repeat: no-repeat;
        opacity: 0.4;
      }
    }
    .container {
      .profile-pic-container {
        text-align: right;
        margin-right: 50px;
        @media screen and (max-width : 767px) {
          text-align: center;
        }
        .profile-pic {
          width: 240px;
          height: 240px;
          margin: 0 0 0px;
          border-radius: 50%;
          border: 5px solid $clr-clearDK;
          display: inline-block;
          background-size: cover;
          background-position: center;
          @media screen and (max-width : 767px) {
            width: 200px;
            height: 200px;
            border: 5px solid #FFF;
            margin: 0 auto 10px;
          }
        }
      }
      .player-data-container {
        text-align: left;
        @media screen and (max-width : 767px) {
          padding: 15px;
        }
        .player-data {
          padding: 5px 0;
          .label {
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
            color: rgba(255, 255, 255, 0.65);
            margin: 0;
            padding: 0;
          }
          .value {
            font-size: 18px;
            text-transform: uppercase;
            color: #FFF;
          }
          &.player-name {
            .player-firstname {
              font-size: 30px;
              font-weight: 600;
              color: #FFF;
              margin: 0;
              padding: 0;
              @media screen and (max-width : 767px) {
                font-size: 20px;
              }
            }
            .player-lastname {
              font-size: 40px;
              font-weight: 700;
              color: $clr-warning;
              margin: 0;
              @media screen and (max-width : 767px) {
                font-size: 28px;
              }
            }
          }
        }
      }
      .profile-social-links {
        margin: -10px 0;
        background-color: rgba(0, 0, 0, 0.15);
        padding: 6px 0;
        border-radius: 35px;
        width: 70px;
        li {
          @media screen and (max-width : 767px) {
            margin-top: 15px;
            display: inline-block;
          }
          a {
            width: 60px;
            height: 60px;
            line-height: 60px;
            font-size: 24px;
            text-align: center;
            display: block;
            color: #FFF;
            border-radius: 50%;
            display: inline-block;
          }
          &:hover {
            a {
              &.facebook { background-color: $clr-facebook }
              &.twitter { background-color: $clr-twitter }
              &.googlep { background-color: $clr-google }
              &.instagram { background: radial-gradient(circle at 33% 100%, #FED373 4%, #F15245 30%, #D92E7F 62%, #9B36B7 85%, #515ECF); }
            }
          }
        }
      }
    }
  }
}
