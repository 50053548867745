/*!
 *   DEVCO COLORES LLB v1.0.0
 *   Author: Devco
!*/


/*  COLORES
========================== */

$clr-greenLPB       : #2196F3; //#6887ff;
$clr-greenLPBLT     : #64B5F6; //#7591ff;
$clr-greenLPBDK     : #1976D2; //#5b7dff;

$clr-carbon: #333;
$clr-carbonLT: #525252;
$clr-carbonDK: #212121;

$clr-clear      : rgba(255, 255, 255, .87);

$clr-black      : #2a2b3c;
$clr-blackLT    : #2f3044;
$clr-blackDK    : #252635;

$clr-dark       :#37474F;
$clr-darkLT     :#607D8B;
$clr-darkDK     :#263238;

$clr-grey       : #9E9E9E;
$clr-greyLT     : #E0E0E0;
$clr-greyDK     : #616161;

$clr-mint       : #0cc2aa;
$clr-mintLT     : #0dceb5;
$clr-mintDK     : #0bb69f;

$clr-violet     : #3F51B5;
$clr-violetLT   : #7986CB;
$clr-violetDK   : #303F9F;

$clr-yellow     : #FFC107;
$clr-yellowLT   : #FFD54F;
$clr-yellowDK   : #FFA000;

$clr-blue       : #2196F3; //#6887ff;
$clr-blueLT     : #64B5F6; //#7591ff;
$clr-blueDK     : #1976D2; //#5b7dff;

$clr-green      : #4CAF50;
$clr-greenLT    : #81C784;
$clr-greenDK    : #388E3C;

$clr-pink       : #E91E63;
$clr-pinkLT     : #F06292;
$clr-pinkDK     : #C2185B;

$clr-red        : #bc2431;
$clr-redLT      : #e22c3c;
$clr-redDK      : #9e1e37;

$clr-orange     : #FF5722;
$clr-orangeLT   : #FF6E40;
$clr-orangeDK   : #E64A19;
