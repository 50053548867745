/*!
 *   DEVCO FONTS MONTSERRAT & HIND v1.0.0
 *   Author: Devco
!*/


/*  FONTS
========================== */

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,400i,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Hind:400,600');

$font-montserrat   : 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-hind : 'Hind', 'Helvetica Neue', Helvetica, Arial, sans-serif;
