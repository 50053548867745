/*!
 *   DEVCO COLORES MATERIAL DESIGN v1.0.0
 *   Author: Devco
!*/


/*  COLORES
========================== */

$clr-carbon     : #424242;
$clr-carbonLT   : #616161;
$clr-carbonDK   : #212121;

$clr-clear      : #F9F9F9;
$clr-clearDK    : #edeef0;

$clr-black      : #000;
$clr-white      : #FFF;

$clr-dark       : #353637;
$clr-darkLT     : #46484A;
$clr-darkDK     : #2E2F30;

$clr-grey       : #BDBDBD;
$clr-greyLT     : #E0E0E0;
$clr-greyDK     : #757575;

$clr-mint       : #00796B;
$clr-mintLT     : #009688;
$clr-mintDK     : #004D40;

$clr-violet     : #512DA8;
$clr-violetLT   : #673AB7;
$clr-violetDK   : #311B92;

$clr-yellow     : #FFA000;
$clr-yellowLT   : #FFC107;
$clr-yellowDK   : #FF6F00;

$clr-gold       : #FFEB3B;
$clr-goldLT     : #FFEE58;
$clr-goldDK     : #FDD835;

$clr-blue       : #1976D2; //#6887ff;
$clr-blueLT     : #42A5F5; //#7591ff;
$clr-blueDK     : #13589D; //#5b7dff;

$clr-lightblue  : #03A9F4; //#6887ff;
$clr-lightblueLT: #2196F3; //#7591ff;
$clr-lightblueDK: #0288D1; //#5b7dff;

$clr-green      : #388E3C;
$clr-greenLT    : #4CAF50;
$clr-greenDK    : #1B5E20;

$clr-pink       : #E91E63;
$clr-pinkLT     : #F06292;
$clr-pinkDK     : #C2185B;

$clr-red        : #D32F2F;
$clr-redLT      : #F44336;
$clr-redDK      : #B71C1C;

$clr-orange     : #F57C00;
$clr-orangeLT   : #FF9800;
$clr-orangeDK   : #E65100;

$clr-facebook     : #3B5998;
$clr-facebookAlt  : #6D84B4;

$clr-twitter    : #00B6F1;
$clr-twitterAlt : #2290BF;

$clr-google     : #DF4A32;
$clr-googleAlt  : #CD3B28;

$clr-instagram    : #458eff;
$clr-instagramAlt : #125688;
