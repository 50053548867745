/*!
 *   DEVCO CORE CSS v1.0.0
 *   Author: Devco
!*/


/*  VARIABLES
========================== */

@import 'fonts/fonts-montserrat-hind';
@import 'colors/colors-lpb';
@import 'colors/colors-material';


/*  LPB VARIABLES
========================== */

//@import 'colors/colors-alexis';

/*$clr-primary    : $clr-greenLPB;
$clr-primaryLT  : $clr-greenLPBLT;
$clr-primaryDK  : $clr-greenLPBDK;*/

$clr-primary    : $clr-blue;
$clr-primaryLT  : $clr-blueLT;
$clr-primaryDK  : $clr-blueDK;

$clr-success    : $clr-green;
$clr-successLT  : $clr-greenLT;
$clr-successDK  : $clr-greenDK;

$clr-warning    : $clr-yellow;
$clr-warningLT  : $clr-yellowLT;
$clr-warningDK  : $clr-yellowDK;

$clr-danger    : $clr-red;
$clr-dangerLT  : $clr-redLT;
$clr-dangerDK  : $clr-redDK;

//Variables de Font Awesome
$fa-var-check-square  : "\f14a";
$fa-var-check-square-o: "\f046";
$fa-var-square        : "\f0c8";
$fa-var-square-o      : "\f096";

//Variables de Media Queries
$device-xl     : "screen and (min-width : 1680px)";
$device-lg     : "screen and (max-width : 1679px)";
$device-md     : "screen and (max-width : 1199px)";
$device-sm     : "screen and (max-width : 991px)";
$device-xs     : "screen and (max-width : 767px)";
$device-xxs    : "screen and (max-width : 479px)";
$device-tablet : "screen and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1)";


/* FUNCTIONS
================================ */
@function longshadow($color_a, $color_b, $stepnum, $opacity: 1) {
  $gradient_steps: null;
  @for $i from 1 through $stepnum {
    $weight: ( ( $i - 1 ) / $stepnum ) * 100;
    $colour_mix: mix($color_b, rgba($color_a, $opacity), $weight);
    $seperator: null;
    @if($i != $stepnum){
      $seperator: #{','};
    }
    $gradient_steps: append(#{$gradient_steps}, #{$i}px #{$i}px $colour_mix $seperator );
  }
  @return $gradient_steps;
}



/*  CORE
========================== */

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
}

body {
  position: relative;
  font-family: $font-montserrat !important;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87);
  background-color: #f0f0f0;
  -webkit-print-color-adjust: exact;
  overflow-x: hidden;
  //overflow-y: hidden;
}

/* H1 - H6 font */
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $font-montserrat !important;
  margin: 0 0 .75em;
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: -.02em;
}

p {
  font-family: $font-hind;
}

.label {
  line-height: unset;
  margin-bottom: 0;
}

label {
  &.error {
    font-weight: 300;
    color: red;
    position: absolute;
    /* width: 100%; */
    left: 0;
    right: 0;
    bottom: -26px;
    text-align: center;
  }
}

input:focus, textarea:focus {
  outline: none !important;
  box-shadow: none !important;
}

.form-control {
  color: #646464;
  border: 1px solid #e6e6e6;
  border-radius: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all .15s ease-out;
  transition: all .15s ease-out;
  &:focus {
    border-color: #ccc;
    background-color: #fcfcfc;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

textarea {
  resize: none;
}

.form-horizontal .control-label {
  padding-top: 7px;
  margin-bottom: 0;
  text-align: right;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
  font-family: $font-montserrat;
}

ul, ol {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
  color: #777;
  font-size: 12px;
  line-height: 1.5;
  //margin-bottom: 20px;
}

.list-group {
    border-radius: 3px;
    background: #fff
}
.list-group.no-radius .list-group-item {
    border-radius: 0!important
}
.list-group.no-borders .list-group-item {
    border: none
}
.list-group.no-border .list-group-item {
    border-width: 1px 0
}
.list-group.no-border .list-group-item:first-child {
    border-top-width: 0
}
.list-group.no-border .list-group-item:last-child {
    border-bottom-width: 0
}
.list-group.no-bg .list-group-item {
    background-color: transparent
}
.list-group.list-group-gap {
    background: 0 0
}
.list-group-item {
    border-color: rgba(120, 130, 140, .065);
    background: 0 0;
    padding: 12px 16px;
    background-clip: padding-box
}
.list-group-item.media {
    margin-top: 0
}
.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
    color: inherit;
    background-color: rgba(0, 0, 0, .065);
    border-color: rgba(120, 130, 140, .065);
    border-bottom-color: rgba(120, 130, 140, .065)
}
.list-group-item.active a,
.list-group-item.active:focus a,
.list-group-item.active:hover a {
    color: inherit
}
.list-group-item:first-child {
    border-top-color: rgba(120, 130, 140, .13)
}
.list-group-item:last-child {
    border-bottom-color: rgba(120, 130, 140, .13)
}
.list-group-alt .list-group-item.active:nth-child(2n+2),
.list-group-alt .list-group-item:nth-child(2n+2) {
    background-color: rgba(0, 0, 0, .065)
}
.list-group-lg .list-group-item {
    padding: 16px 24px
}
.list-group-md .list-group-item {
    padding-top: 16px;
    padding-bottom: 16px
}
.list-group-sm .list-group-item {
    padding: 10px 12px
}
.list-group-gap .list-group-item {
    margin-bottom: 5px;
    border-radius: 3px
}
.list-group-gap .list-group-item:first-child {
    border-top-color: rgba(120, 130, 140, .065)
}
.list-group-gap .list-group-item:last-child {
    border-bottom-color: rgba(120, 130, 140, .065)
}
a.list-group-item {
    color: inherit
}
a.list-group-item.hover,
a.list-group-item:focus,
a.list-group-item:hover {
    color: inherit;
    background-color: rgba(0, 0, 0, .065)
}

.no-border {
    border-color: transparent!important;
    border-width: 0!important;
}

.no-shadow {
    -webkit-box-shadow: none!important;
    box-shadow: none!important
}

.clear {
    display: block;
    overflow: hidden;
}

.scrollable {
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

i {
  @extend .noselectable;
}

select {
  outline: none;
  cursor: pointer;
  &:focus {
    box-shadow: none !important;
  }
}

.form-group-fretson {
  padding: 18px 0 24px;
  position: relative;
}

.form-control {
  border-radius: 0;
  box-shadow: none;
}
.float-label {
  .input-fretson + label {
    top: 20px;
    font-size: 1em;
  }
  .input-fretson:focus + label,
  .input-fretson:not(:placeholder-shown) + label {
    top: 0;
    font-size: .85em;
    color: $clr-primary;
    opacity: .8;
  }
  .input-fretson:focus,
  .input-fretson:not(:placeholder-shown) {
    border-color: $clr-primary;
  }
}

.accordion {
  .acc-item {
    .acc-heading {
      display: block;
      padding: 7px 10px;
      border-top: 1px solid rgba(0,0,0,0.05);
      &.collapsed:hover {
        background: #f9f9f9;
      }
      &:not(.collapsed) {
        background-color: $clr-primary;
        color:#fff;
      }
    }
    .acc-body {
      border-left: 4px solid $clr-primaryDK;
      border-bottom: 2px solid $clr-primaryLT;
      .dataTables_wrapper {
        .mdl-grid {
          padding: 0 !important;
          &:first-child {
            .mdl-cell {
              &:first-child {
                display: none;
              }
              &:last-child {
                width: 100%;
              }
              .dataTables_filter {
                label {
                  display: block;
                  .form-control {
                    width: 100%;
                    display: block;
                    margin: 0;
                    font-weight: 500;
                    border: none;
                  }
                }
              }
            }
          }
          .mdl-cell {
            max-height: 350px;
            overflow: auto;
            margin: 0;
            &.mdl-cell--12-col {
              width: 100% !important;
            }
            td {
              a {
                display: block
              }
            }
          }
        }
      }
      .table {
        thead {
          th {
            text-align: left;
            padding: 3px 8px;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-size: 8px;
            line-height: 16px;
            height: 25px;
          }
        }
      }
    }
  }
}

hr {
  margin-top: 30px;
  margin-bottom: 30px;
  border: 0;
  border-top: 1px solid rgba(120,130,140,0.13);
}

.center-block {
  margin: 0 auto;
  padding: 30px 0;
}

.clearfix { clear: both; }

[disabled] {
   pointer-events: none;
   cursor: default;
}

/*.row-col {
  width: 100%;
  height: 100%;
  display: table;
  table-layout: fixed;
  border-spacing: 0;
  & > [class*=" col-"],
  & > [class*="col-"] {
    vertical-align: top;
    padding: 0;
    position: relative;
  }
}*/
.row-col {
    display: table;
    table-layout: fixed;
    border-spacing: 0;
    width: 100%;
    width: calc(100% + 15px);
    height: 100%;
    margin-right: -15px;
    margin-left: -15px;
    & > [class*=col-],
    & > [class*=" col-"] {
      vertical-align: top;
      float: none;
      padding: 0;
      position: static
    }
}
@media (min-width: 992px) {
  .row-col>[class*=col-lg],
  .row-col>[class*=" col-lg"] {
    display: table-cell;
    height: 100%
  }
}
@media (min-width: 768px) {
  .row-col>[class*=col-md],
  .row-col>[class*=" col-md"] {
    display: table-cell;
    height: 100%
  }
}
@media (min-width: 544px) {
  .row-col>[class*=col-sm],
  .row-col>[class*=" col-sm"] {
    display: table-cell;
    height: 100%
  }
}
.row-col>[class*=col-xs],
.row-col>[class*=" col-xs"] {
  display: table-cell;
  height: 100%
}
.row-row {
  display: table-row;
  height: 100%;
}

.row-cell {
  display: table-cell;
  vertical-align: top;
}
.ie {
  .row-body {
    display: table-cell;
    overflow: auto;
    .row-inner {
      overflow: visible !important;
    }
  }
}
.row-body {
  position: relative;
  height: 100%;
  width: 100%;
  .row-inner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.overlay {
  position: fixed;
  opacity: 1;
  background-color: rgba(0,0,0,.6);
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.pull-center {
  position: absolute;
  left: 50%;
  & > * {
    margin-left: -50%;
    float: left;
  }
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  outline: 0; }

a:hover,
a:focus {
  color: inherit;
  text-decoration: none; }


.dataTables_wrapper {
  .dataTables_length {
    select {
      margin: 0 5px;
      font-weight: 500 !important;
    }
  }
  .dataTables_filter {
    input {
      font-weight: 500 !important;
    }
  }
}


.noselectable {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.btn {
  cursor: pointer;
  color: #fff !important;
}

.btn-flat {
  border-radius: 0 !important;
}

.btn-group {
  display: flex;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  background-color: $clr-primaryDK;
  border-radius: 0;
}

.input-group {
  .input-group-btn {
    &:last-child > .btn {
      padding: 6px;
      width: 36px;
    }
  }
}

.btn-default {
  background-color: $clr-greyLT;
  border-color: $clr-greyLT;
  &:hover {
    background-color: $clr-greyDK;
    border-color: $clr-greyDK;
  }
}

.btn-primary {
  background-color: $clr-primary;
  border-color: $clr-primary;
  &:hover {
    background-color: $clr-primaryDK;
    border-color: $clr-primaryDK;
  }
}

.navbar-brand {
  i {
    font-size: 28px;
    color: $clr-primary;
  }
  span {
    font-size: 22px;
    display: inline-block;
    position: relative;
    margin-left: 5px;
    font-family: $font-montserrat;
    font-weight: 700;
    top: -5px;
    letter-spacing: -1px;
  }
}

.panel-group .panel {
    border-radius: 0;
}

/*  DATEPICKER */
.daterangepicker {
  td.active,
  td.active:hover {
      background-color: $clr-primary;
      border-color: transparent;
      color: #fff;
  }
  .ranges li {
    color: $clr-primary;
    &.active,
    &:hover {
      background-color: $clr-primary;
      border: 1px solid $clr-primary;
      color: #fff;
    }
  }
  .applyBtn.btn-success {
    background-color: $clr-success;
    border-color: $clr-success;
  }
}

.modal-backdrop {
  z-index: 9999;
}
.modal {
  z-index: 10000;
  .modal-dialog {
    top: 50% !important;
    margin: 0 auto;
    -webkit-transform: translateY(-50%) !important;
    transform: translateY(-50%) !important;
    max-height: 85%;
    overflow-y: auto;
    overflow-x: hidden;
    @media #{$device-tablet} {
      margin-top: 50% !important;
      -webkit-transform: translateY(0) !important;
      transform: translateY(0) !important;
    }
    .modal-content {
      .modal-header {
        .modal-title {
          font-size: 14px;
          text-transform: uppercase;
        }
      }
    }
  }
}

.box,
.box-color {
  //background-color: #fff;
  position: relative;
  margin-bottom: 24px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.03), 0 1px 0 rgba(0, 0, 0, 0.03);
  .box-header {
    padding: 30px 25px;
    /*height: 76px;
    padding: 27px 23px;
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid #e4e7ed;
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 6px;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: $clr-primary;
    }*/
    h2, h3, h4 {
      margin: 0;
      font-size: 18px;
      line-height: 1;
    }
    h3 {
      font-size: 16px;
      &.zonas {
        span {
          display: inline-block;
          margin-right: -3px;
        }
        select {
          width: calc(100% - 50px);
          border: none;
          border-radius: 0;
          background-color: transparent;
          optgroup {
            cursor: default;
            font-weight: 500;
            font-size: 12px;
            .tema {
              cursor: pointer;
            }
          }
        }
      }
    }
    h4 {
      font-weight: 700;
      font-size: 18px;
      line-height: 1.2em;
      letter-spacing: -0.02em;
      margin: 0;
      text-transform: uppercase;
      font-style: normal;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      small {
        margin-left: 10px;
        font-size: 12px;
        opacity: 1;
        letter-spacing: 0;
      }
    }
    & > small {
      display: block;
      margin-top: 4px;
      opacity: 0.6;
    }
    .btn {
      border-color: #dbdfe6;
      color: #9a9da2;
      padding: 8px 24px;
      font-size: 12px;
      line-height: 16px;
      border-radius: 2px;
      top: -6px;
      position: relative;
      &.btn-outline {
        background: none;
      }
    }
  }
  .box-body {
    padding: 24px;
  }
  .box-footer {
    padding: 24px;
  }
  .box-divider {
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
    margin: 0 16px;
    height: 0;
  }
  .box-tool {
    position: absolute;
    right: 16px;
    top: 14px;
  }
}
.list {
  padding-left: 0;
  padding-right: 0;
  border-radius: 3px;
  &.no-border {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .list-item {
    display: block;
    position: relative;
    padding: 12px 16px;
    &::after {
      content: "";
      display: table;
      clear: both;
    }
    &:last-child .list-body:after {
      border: none;
    }
    .list-left {
      float: left;
      padding-right: 1rem;
      + .list-body {
        margin-left: 56px;
      }
    }
    .list-body {
      h3 {
        font-size: 16px;
        margin: 0 0 3px 0;
        font-weight: normal;
      }
      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        border-bottom: 1px solid rgba(120, 130, 140, 0.065);
      }
    }
  }
  &.no-border {
    .list-item {
      padding-top: 8px;
      padding-bottom: 8px;
      .list-body {
        &:after {
          display: none;
        }
      }
    }
  }
  &.no-padding .list-item {
    padding-left: 0;
    padding-right: 0;
  }
}
.inset .list-body:after {
  left: 72px;
}

/*  WIDTHS
================================ */

.w-8 {
    width: 8px;
    height: 8px
}
.w-16 {
    width: 16px;
    height: 16px
}
.w-20 {
    width: 20px;
    height: 20px
}
.w-24 {
    width: 24px;
    height: 24px
}
.w-32 {
    width: 32px;
    height: 32px;
    line-height: 32px
}
.w-40 {
    width: 40px;
    height: 40px;
    line-height: 40px;
    display: inline-block
}
.w-48 {
    width: 48px;
    height: 48px;
    line-height: 48px
}
.w-56 {
    width: 56px;
    height: 56px;
    line-height: 56px
}
.w-64 {
    width: 64px
}
.w-96 {
    width: 96px
}
.w-128 {
    width: 128px
}
.w-xxs {
    width: 60px
}
.w-xs {
    width: 90px
}
.w-sm {
    width: 120px
}
.w {
    width: 180px
}
.w-md {
    width: 200px
}
.w-lg {
    width: 240px
}
.w-xl {
    width: 280px
}
.w-xxl {
    width: 320px
}
.w-full {
    width: 100%
}
.w-auto {
    width: auto
}
.h-auto {
    height: auto
}
.h-full {
    height: 100%
}
.h-v {
    height: 100vh
}
.h-v-5 {
    height: 50vh
}
@media (max-width: 767px) {
    .w-auto-sm {
        width: auto
    }
    .w-full-sm {
        width: 100%
    }
}
@media (max-width: 543px) {
    .w-auto-xs {
        width: auto
    }
    .w-full-xs {
        width: 100%
    }
}


/* PADDING & MARGIN
================================ */

.no-display { display: none !important; }
.display-block { display: block !important; }
.display-iblock { display: inline-block !important; }
.display-flex { display: flex !important; }

.width-130 { width: 130px; }
.width-150 { width: 150px; }
.width-250 { width: 250px; }
.width-300 { width: 300px; }
.width-350 { width: 350px; }

.container-full { width: 100%; }

.no-margin { margin: 0 !important; }
.margin-top-0 {  margin-top: 0px !important; }
.margin-right-0 {  margin-right: 0px !important; }
.margin-bottom-0 {  margin-bottom: 0px !important; }
.margin-left-0 {  margin-left: 0px !important; }
.margin-5 {  margin: 5px !important; }
.margin-top-5 {  margin-top: 5px !important; }
.margin-right-5 {  margin-right: 5px !important; }
.margin-bottom-5 {  margin-bottom: 5px !important; }
.margin-left-5 {  margin-left: 5px !important; }
.margin-15 {  margin: 15px !important; }
.margin-top-15 {  margin-top: 15px !important; }
.margin-right-15 {  margin-right: 15px !important; }
.margin-bottom-15 {  margin-bottom: 15px !important; }
.margin-left-15 {  margin-left: 15px !important; }
.margin-20 {  margin: 20px !important; }
.margin-top-20 {  margin-top: 20px !important; }
.margin-right-20 {  margin-right: 20px !important; }
.margin-bottom-20 {  margin-bottom: 20px !important; }
.margin-left-20 {  margin-left: 20px !important; }
.margin-25 {  margin: 25px !important; }
.margin-top-25 {  margin-top: 25px !important; }
.margin-right-25 {  margin-right: 25px !important; }
.margin-bottom-25 {  margin-bottom: 25px !important; }
.margin-left-25 {  margin-left: 25px !important; }
.margin-50 {  margin: 50px !important; }
.margin-top-50 {  margin-top: 50px !important; }
.margin-right-50 {  margin-right: 50px !important; }
.margin-bottom-50 {  margin-bottom: 50px !important; }
.margin-left-50 {  margin-left: 50px !important; }

.no-padding { padding: 0 !important; }
.padding-top-0 {  padding-top: 0px !important; }
.padding-right-0 {  padding-right: 0px !important; }
.padding-bottom-0 {  padding-bottom: 0px !important; }
.padding-left-0 {  padding-left: 0px !important; }
.padding-10 {  padding: 10px !important; }
.padding-top-10 {  padding-top: 10px !important; }
.padding-right-10 {  padding-right: 10px !important; }
.padding-bottom-10 {  padding-bottom: 10px !important; }
.padding-left-10 {  padding-left: 10px !important; }
.padding-15 {  padding: 15px !important; }
.padding-top-15 {  padding-top: 15px !important; }
.padding-right-15 {  padding-right: 15px !important; }
.padding-bottom-15 {  padding-bottom: 15px !important; }
.padding-left-15 {  padding-left: 15px !important; }
.padding-20 {  padding: 20px !important; }
.padding-top-20 {  padding-top: 20px !important; }
.padding-right-20 {  padding-right: 20px !important; }
.padding-bottom-20 {  padding-bottom: 20px !important; }
.padding-left-20 {  padding-left: 20px !important; }
.padding-25 {  padding: 25px !important; }
.padding-top-25 {  padding-top: 25px !important; }
.padding-right-25 {  padding-right: 25px !important; }
.padding-bottom-25 {  padding-bottom: 25px !important; }
.padding-left-25 {  padding-left: 25px !important; }
.padding-50 {  padding: 50px !important; }
.padding-top-50 {  padding-top: 50px !important; }
.padding-right-50 {  padding-right: 50px !important; }
.padding-bottom-50 {  padding-bottom: 50px !important; }
.padding-left-50 {  padding-left: 50px !important; }
.padding-bottom-120 {  padding-bottom: 120px !important; }


.rounded, .circle {
  border-radius: 50%;
}


span[class^='w-'],
span[class*=' w-'] {
  color: rgba(255, 255, 255, .95) !important;
}

.w-8 {
  width: 8px;
  height: 8px;
  display: inline-block; }

.w-16 {
  width: 16px;
  height: 16px;
  display: inline-block; }

.w-20 {
  width: 20px;
  height: 20px;
  display: inline-block; }

.w-24 {
  width: 24px;
  height: 24px;
  display: inline-block;
  text-align: center; }

.w-32 {
  width: 32px;
  height: 32px;
  line-height: 32px;
  display: inline-block;
  text-align: center; }

.w-40 {
  width: 40px;
  height: 40px;
  line-height: 42px;
  display: inline-block;
  text-align: center;
  font-family: $font-montserrat;
}

.w-48 {
  width: 48px;
  height: 48px;
  line-height: 48px;
  display: inline-block;
  text-align: center; }

.w-56 {
  width: 56px;
  height: 56px;
  line-height: 56px;
  display: inline-block;
  text-align: center; }

/*  BACKGROUNDS
========================== */

.bg-gris {
  background-color: $clr-greyLT !important;
}

.bg-muted {
    background-color: #999999
}
a.bg-muted:hover,
a.bg-muted:focus {
    background-color: #808080
}
.bg-primary {
    background-color: $clr-primary;
    color: #fff;
}
a.bg-primary:hover,
a.bg-primary:focus {
    background-color: #3675c5
}
.bg-primary-op {
    background-color: rgba(92, 144, 210, 0.75)
}
a.bg-primary-op:hover,
a.bg-primary-op:focus {
    background-color: rgba(54, 117, 197, 0.75)
}
.bg-primary-dark {
    background-color: #3e4a59
}
a.bg-primary-dark:hover,
a.bg-primary-dark:focus {
    background-color: #29313b
}
.bg-primary-dark-op {
    background-color: rgba(62, 74, 89, 0.83)
}
a.bg-primary-dark-op:hover,
a.bg-primary-dark-op:focus {
    background-color: rgba(41, 49, 59, 0.83)
}
.bg-primary-darker {
    background-color: #2c343f
}
a.bg-primary-darker:hover,
a.bg-primary-darker:focus {
    background-color: #171b21
}
.bg-primary-light {
    background-color: #98b9e3
}
a.bg-primary-light:hover,
a.bg-primary-light:focus {
    background-color: #709ed8
}
.bg-primary-lighter {
    background-color: #ccdcf1
}
a.bg-primary-lighter:hover,
a.bg-primary-lighter:focus {
    background-color: #a4c1e6
}
.bg-body {
    background-color: #f5f5f5
}
.bg-success {
    background-color: $clr-success
}
a.bg-success:hover,
a.bg-success:focus {
    background-color: $clr-successDK
}
.bg-warning {
    background-color: $clr-warning
}
a.bg-warning:hover,
a.bg-warning:focus {
    background-color: $clr-warningDK
}
.bg-info {
    background-color: $clr-mint
}
a.bg-info:hover,
a.bg-info:focus {
    background-color: $clr-mintDK
}
.bg-danger {
    background-color: $clr-danger
}
a.bg-danger:hover,
a.bg-danger:focus {
    background-color: $clr-dangerDK
}
.bg-success-light {
    background-color: #e0f5e9
}
a.bg-success-light:hover,
a.bg-success-light:focus {
    background-color: #b9e9ce
}
.bg-warning-light {
    background-color: #fdf3e5
}
a.bg-warning-light:hover,
a.bg-warning-light:focus {
    background-color: #f9ddb6
}
.bg-info-light {
    background-color: #edf6fd
}
a.bg-info-light:hover,
a.bg-info-light:focus {
    background-color: #bfdff8
}
.bg-danger-light {
    background-color: #f9eae8
}
a.bg-danger-light:hover,
a.bg-danger-light:focus {
    background-color: #eec5c0
}
.bg-white {
    background-color: #fff
}
a.bg-white:hover,
a.bg-white:focus {
    background-color: #e6e6e6
}
.bg-white-op {
    background-color: rgba(255, 255, 255, 0.075)
}
.bg-crystal-op {
    background-color: rgba(255, 255, 255, 0.15)
}
.bg-black {
    background-color: #000
}
a.bg-black:hover,
a.bg-black:focus {
    background-color: #000
}
.bg-black-op {
    background-color: rgba(0, 0, 0, 0.4)
}
.bg-gray {
    background-color: #c9c9c9
}
a.bg-gray:hover,
a.bg-gray:focus {
    background-color: #b0b0b0
}
.bg-gray-dark {
    background-color: #999999
}
a.bg-gray-dark:hover,
a.bg-gray-dark:focus {
    background-color: #808080
}
.bg-gray-darker {
    background-color: #393939
}
a.bg-gray-darker:hover,
a.bg-gray-darker:focus {
    background-color: #202020
}
.bg-gray-light {
    background-color: #f3f3f3
}
a.bg-gray-light:hover,
a.bg-gray-light:focus {
    background-color: #dadada
}
.bg-gray-lighter {
    background-color: #f9f9f9
}
a.bg-gray-lighter:hover,
a.bg-gray-lighter:focus {
    background-color: #e0e0e0
}



/*  TEXTO
============================== */

a.primary:hover {
    background-color: $clr-primaryDK
}
a.text-primary:hover {
    color: $clr-primaryDK
}
.text-primary,
.text-primary-hover a:hover {
    color: $clr-primary !important
}
a.warn:hover {
    background-color: $clr-warningDK
}
a.text-warn:hover {
    color: $clr-warningDK
}
.text-warn,
.text-warn-hover a:hover {
    color: $clr-warning !important
}
a.accent:hover {
    background-color: $clr-violetDK
}
a.text-accent:hover {
    color: $clr-violetDK
}
.text-accent,
.text-accent-hover a:hover {
    color: $clr-violet !important
}
a.success:hover {
    background-color: $clr-successDK
}
a.text-success:hover {
    color: $clr-successDK
}
.text-success,
.text-success-hover a:hover {
    color: $clr-success !important
}
a.info:hover {
    background-color: $clr-mintDK
}
a.text-info:hover {
    color: $clr-mintDK
}
.text-info,
.text-info-hover a:hover {
    color: $clr-mint !important
}
a.warning:hover {
    background-color: $clr-warningDK
}
a.text-warning:hover {
    color: $clr-warningDK
}
.text-warning,
.text-warning-hover a:hover {
    color: $clr-warning !important
}
a.danger:hover {
    background-color: $clr-dangerDK
}
a.text-danger:hover {
    color: $clr-dangerDK
}
.text-danger,
.text-danger-hover a:hover {
    color: $clr-danger !important
}
a.dark:hover {
    background-color: $clr-darkDK
}
a.text-dark:hover {
    color: $clr-darkDK
}
.text-dark,
.text-dark-hover a:hover {
    color: $clr-dark !important
}
a.blue:hover {
    background-color: $clr-primaryDK
}
a.text-blue:hover {
    color: $clr-primaryDK
}
.text-blue,
.text-blue-hover a:hover {
    color: $clr-primary !important
}
.text-white {
    color: #fff!important
}
.text-white-lt {
    color: rgba(255, 255, 255, .54)
}
.text-white-dk {
    color: rgba(255, 255, 255, .87)
}
.text-black {
    color: #000
}
.text-black-lt {
    color: rgba(0, 0, 0, .54)
}
.text-black-dk {
    color: rgba(0, 0, 0, .87)
}
.text-muted {
    color: inherit!important;
    opacity: .6
}
.text-color {
    color: rgba(0, 0, 0, .87)
}

._100 {
  font-weight: 100; }

._200 {
  font-weight: 200; }

._300 {
  font-weight: 300; }

._400 {
  font-weight: 400; }

._500 {
  font-weight: 500; }

._600 {
  font-weight: 600; }

._700 {
  font-weight: 700; }

._800 {
  font-weight: 800; }

.text {
  font-size: 16px; }

.text-xs {
  font-size: 12px; }

.text-sm {
  font-size: 14px; }

.text-md {
  font-size: 18px; }

.text-lg {
  font-size: 24px; }

.text-2x {
  font-size: 28px; }

.text-3x {
  font-size: 42px; }

.text-4x {
  font-size: 56px; }


/*  BACKGROUNDS
===================================== */

.bg-black,
.bg-danger,
.bg-dark,
.bg-grey,
.bg-info,
.bg-primary,
.bg-success,
.bg-warn,
.bg-warning {
  color: rgba(255, 255, 255, .95) !important
}

.bg-light {
  background-color: #f8f8f8;
}
.bg-primary {
  background-color: $clr-primary;
  span[class^='w-'],
  span[class*=' w-'] {
    background-color: $clr-primaryDK;
  }
}
.bg-success {
  background-color: $clr-success;
  span[class^='w-'],
  span[class*=' w-'] {
    background-color: $clr-successDK;
  }
}
.bg-info {
  background-color: $clr-mint;
  span[class^='w-'],
  span[class*=' w-'] {
    background-color: $clr-mintDK;
  }
}
.bg-warning {
  background-color: $clr-warning;
  span[class^='w-'],
  span[class*=' w-'] {
    background-color: $clr-warningDK;
  }
}
.bg-danger {
  background-color: $clr-danger;
  span[class^='w-'],
  span[class*=' w-'] {
    background-color: $clr-dangerDK;
  }
}

/*  BORDERS
========================= */

.no-border {
    border-color: transparent;
    border-width: 0
}
.no-borders {
    border-width: 0!important
}
.b {
    border-color: rgba(120, 130, 140, .13);
    background-clip: padding-box
}
.b-a {
    border: 1px solid rgba(120, 130, 140, .13)
}
.b-t {
    border-top: 1px solid rgba(120, 130, 140, .13)
}
.b-r {
    border-right: 1px solid rgba(120, 130, 140, .13)
}
.b-b {
    border-bottom: 1px solid rgba(120, 130, 140, .13)
}
.b-l {
    border-left: 1px solid rgba(120, 130, 140, .13)
}
.b-2x {
    border-width: 2px
}
.b-3x {
    border-width: 3px
}
.b-4x {
    border-width: 4px
}
.b-5x {
    border-width: 5px
}
.b-t-2x {
    border-top-width: 2px!important
}
.b-t-3x {
    border-top-width: 3px!important
}
.b-t-4x {
    border-top-width: 4px!important
}
.b-t-5x {
    border-top-width: 5px!important
}
.b-r-2x {
    border-right-width: 2px!important
}
.b-r-3x {
    border-right-width: 3px!important
}
.b-r-4x {
    border-right-width: 4px!important
}
.b-r-5x {
    border-right-width: 5px!important
}
.b-b-2x {
    border-bottom-width: 2px!important
}
.b-b-3x {
    border-bottom-width: 3px!important
}
.b-b-4x {
    border-bottom-width: 4px!important
}
.b-b-5x {
    border-bottom-width: 5px!important
}
.b-l-2x {
    border-left-width: 2px!important
}
.b-l-3x {
    border-left-width: 3px!important
}
.b-l-4x {
    border-left-width: 4px!important
}
.b-l-5x {
    border-left-width: 5px!important
}
.b-primary {
    border-color: #0cc2aa
}
.b-t-primary {
    border-top-color: #0cc2aa
}
.b-r-#e7f9f7 {
    border-right-color: #0cc2aa
}
.b-b-primary {
    border-bottom-color: #0cc2aa
}
.b-l-primary {
    border-left-color: #0cc2aa
}
.b-accent {
    border-color: #a88add
}
.b-t-accent {
    border-top-color: #a88add
}
.b-r-#f6f3fc {
    border-right-color: #a88add
}
.b-b-accent {
    border-bottom-color: #a88add
}
.b-l-accent {
    border-left-color: #a88add
}
.b-warn {
    border-color: #fcc100
}
.b-t-warn {
    border-top-color: #fcc100
}
.b-r-#fff9e6 {
    border-right-color: #fcc100
}
.b-b-warn {
    border-bottom-color: #fcc100
}
.b-l-warn {
    border-left-color: #fcc100
}
.b-success {
    border-color: #6cc788
}
.b-t-success {
    border-top-color: #6cc788
}
.b-r-#f0f9f3 {
    border-right-color: #6cc788
}
.b-b-success {
    border-bottom-color: #6cc788
}
.b-l-success {
    border-left-color: #6cc788
}
.b-info {
    border-color: #6887ff
}
.b-t-info {
    border-top-color: #6887ff
}
.b-r-#f0f3ff {
    border-right-color: #6887ff
}
.b-b-info {
    border-bottom-color: #6887ff
}
.b-l-info {
    border-left-color: #6887ff
}
.b-warning {
    border-color: #f77a99
}
.b-t-warning {
    border-top-color: #f77a99
}
.b-r-#fef2f5 {
    border-right-color: #f77a99
}
.b-b-warning {
    border-bottom-color: #f77a99
}
.b-l-warning {
    border-left-color: #f77a99
}
.b-danger {
    border-color: #f44455
}
.b-t-danger {
    border-top-color: #f44455
}
.b-r-#feecee {
    border-right-color: #f44455
}
.b-b-danger {
    border-bottom-color: #f44455
}
.b-l-danger {
    border-left-color: #f44455
}
.b-light {
    border-color: #f8f8f8
}
.b-t-light {
    border-top-color: #f8f8f8
}
.b-r-#fefefe {
    border-right-color: #f8f8f8
}
.b-b-light {
    border-bottom-color: #f8f8f8
}
.b-l-light {
    border-left-color: #f8f8f8
}
.b-grey {
    border-color: #424242
}
.b-t-grey {
    border-top-color: #424242
}
.b-r-#ececec {
    border-right-color: #424242
}
.b-b-grey {
    border-bottom-color: #424242
}
.b-l-grey {
    border-left-color: #424242
}
.b-dark {
    border-color: #2e3e4e
}
.b-t-dark {
    border-top-color: #2e3e4e
}
.b-r-#eaeced {
    border-right-color: #2e3e4e
}
.b-b-dark {
    border-bottom-color: #2e3e4e
}
.b-l-dark {
    border-left-color: #2e3e4e
}
.b-black {
    border-color: #2a2b3c
}
.b-t-black {
    border-top-color: #2a2b3c
}
.b-r-#eaeaec {
    border-right-color: #2a2b3c
}
.b-b-black {
    border-bottom-color: #2a2b3c
}
.b-l-black {
    border-left-color: #2a2b3c
}
.b-white {
    border-color: #fff
}
.b-t-white {
    border-top-color: #fff
}
.b-r-white {
    border-right-color: #fff
}
.b-b-white {
    border-bottom-color: #fff
}
.b-l-white {
    border-left-color: #fff
}
.b-blue {
    border-color: #2196f3
}
.b-t-blue {
    border-top-color: #2196f3
}
.b-r-#e9f5fe {
    border-right-color: #2196f3
}
.b-b-blue {
    border-bottom-color: #2196f3
}
.b-l-blue {
    border-left-color: #2196f3
}
.no-b-t {
    border-top-width: 0
}
.no-b-r {
    border-right-width: 0
}
.no-b-b {
    border-bottom-width: 0
}
.no-b-l {
    border-left-width: 0
}
.b-dashed {
    border-style: dashed!important
}


/*  BUTTONS
========================== */
.btn {
  font-weight: 700;
  font-family: $font-montserrat;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding: 10px 40px;
  i {
    font-size: initial !important;
  }
  &.btn-icon {
    width: 32px;
    height: 32px;
    padding: 0 !important;
    line-height: 30px !important;
    border-radius: 50% !important;
    margin: 0 auto;
    display: inline-block;
    position: relative;
    font-size: 14px !important;
    &.btn-sm {
      width: 28px;
      height: 28px;
      line-height: 26px !important;
    }
  }
  &.btn-lg{padding:16px 80px;font-size:13px;line-height:1.45em;border-radius:2px;}
  &.btn-sm{padding:10px 38px;font-size:11px;line-height:1.5em;border-radius:2px;}
  &.btn-xs{padding:5px 13px;font-size:9px;line-height:1.5em;border-radius:2px;}
  &.btn-outline{background:none;}
  &.btn-outline.btn-default{border-color:#dbdfe6;color:#9a9da2;}
  &.btn-outline.btn-default:hover{background-color:#9a9da2;border-color:#9a9da2;color:#fff;}
  &.btn-outline.btn-default:hover.btn-circle{background-color:#9a9da2;}
  &.btn-outline.btn-primary{border-color:$clr-primaryDK;color:$clr-primary!important;}
  &.btn-inverse.btn-primary {color: #fff;background-color: $clr-primary;border-color: $clr-primary;}
  &.btn-outline.btn-primary:hover{background-color:$clr-primaryDK;border-color:$clr-primaryDK;color:#fff!important;}
  &.btn-outline.btn-primary:hover.btn-circle{background-color:#1892ed;}
  &.btn-outline.btn-success{border-color:$clr-successDK; color:$clr-successDK !important;}
  &.btn-outline.btn-success:hover{background-color:$clr-successDK;border-color:$clr-successDK;color:#fff !important;}
  &.btn-outline.btn-success:hover.btn-circle{background-color:#1892ed;}
  &.btn-outline.btn-inverse{border-color:#fff;color:#fff;border-width:2px;}
  &.btn-outline.btn-inverse:hover{background-color:#1892ed;border-color:#1892ed;color:#fff;}
  &.btn-outline.btn-inverse:hover i{color:#fff;}
  &.btn-outline.btn-inverse:hover.btn-circle{background-color:#1892ed;}

  &.btn-primary {
    background: $clr-primary;
    border-color: $clr-primary;
  }
  &.btn-success {
    background: $clr-success;
    border-color: $clr-success;
  }
  &.btn-warning {
    background: $clr-warning;
    border-color: $clr-warning;
  }
  &.btn-danger {
    background: $clr-danger;
    border-color: $clr-danger;
  }
  &.btn-info {
    background: $clr-mint;
    border-color: $clr-mint;
  }
  &.btn-primary:hover, &.btn-primary:focus, &.btn-primary:active, &.btn-primary.active, .open .dropdown-toggle.btn-primary {
    background-color: $clr-primaryDK;
    border-color: $clr-primaryDK;
    color: #FFFFFF;
  }
  &.btn-success:hover, &.btn-success:focus, &.btn-success:active, &.btn-success.active, .open .dropdown-toggle.btn-success {
    background-color: $clr-successDK;
    border-color: $clr-successDK;
    color: #FFFFFF;
  }
  &.btn-info:hover, &.btn-info:focus, &.btn-info:active, &.btn-info.active, .open .dropdown-toggle.btn-info {
    background-color: $clr-mintDK;
    border-color: $clr-mintDK;
    color: #FFFFFF;
  }
  &.btn-warning:hover, &.btn-warning:focus, &.btn-warning:active, &.btn-warning.active, .open .dropdown-toggle.btn-warning {
    background-color: $clr-warningDK;
    border-color: $clr-warningDK;
    color: #FFFFFF;
  }
  &.btn-danger:hover, &.btn-danger:focus, &.btn-danger:active, &.btn-danger.active, .open .dropdown-toggle.btn-danger {
    background-color: $clr-dangerDK;
    border-color: $clr-dangerDK;
    color: #FFFFFF;
  }
  &.btn-dark:hover, &.btn-dark:focus, &.btn-dark:active, &.btn-dark.active, .open .dropdown-toggle.btn-dark {
    background-color: #394D5F;
    border-color: #394D5F;
    color: #FFFFFF;
  }
}

/*  CHECKBOXES & RADIOBUTTONS
=============================== */
.checkbox {
    padding-left: 20px;
    cursor: pointer;
    font-weight: 500;
}
.checkbox label {
    display: inline-block;
    padding-left: 5px;
    position: relative
}
.checkbox label::before {
    -o-transition: .3s ease-in-out;
    -webkit-transition: .3s ease-in-out;
    background-color: #fff;
    border-radius: 0;
    border: 1px solid rgba(120, 130, 140, .13);
    content: "";
    display: inline-block;
    height: 17px;
    left: 0;
    margin-left: -20px;
    position: absolute;
    transition: .3s ease-in-out;
    width: 17px;
    outline: 0!important
}
.checkbox label::after {
    color: #2b2b2b;
    display: inline-block;
    font-size: 11px;
    height: 16px;
    left: 0;
    margin-left: -20px;
    padding-left: 3px;
    padding-top: 1px;
    position: absolute;
    top: 0;
    width: 16px
}
.checkbox input[type=checkbox] {
    cursor: pointer;
    opacity: 0;
    z-index: 1;
    outline: 0!important
}
.checkbox input[type=checkbox]:disabled+label {
    opacity: .65
}
.checkbox input[type=checkbox]:focus+label::before {
    outline-offset: -2px;
    outline: 0;
    outline: dotted thin
}
.checkbox input[type=checkbox]:checked+label::after {
    content: "check";
    width: 17px;
    height: 17px;
    line-height: 15px;
    padding-left: 2px;
    background-color: $clr-primary;
    font-family: "Material Icons";
    font-size: 13px;
    color: #FFF;
}
.checkbox input[type=checkbox]:disabled+label::before {
    background-color: #e4e7ea;
    cursor: not-allowed
}
.checkbox.checkbox-circle span::before {
    border-radius: 50%
}
.checkbox.checkbox-inline {
    margin-top: 0
}
.checkbox.checkbox-single span {
    height: 17px
}
.checkbox-primary input[type=checkbox]:checked+span::before {
    background-color: $clr-primary;
    border-color: $clr-primary
}
.checkbox-primary input[type=checkbox]:checked+span::after {
    color: #fff
}
.checkbox-danger input[type=checkbox]:checked+span::before {
    background-color: #fb9678;
    border-color: #fb9678
}
.checkbox-danger input[type=checkbox]:checked+span::after {
    color: #fff
}
.checkbox-info input[type=checkbox]:checked+span::before {
    background-color: #03a9f3;
    border-color: #03a9f3
}
.checkbox-info input[type=checkbox]:checked+span::after {
    color: #fff
}
.checkbox-warning input[type=checkbox]:checked+span::before {
    background-color: #fec107;
    border-color: #fec107
}
.checkbox-warning input[type=checkbox]:checked+span::after {
    color: #fff
}
.checkbox-success input[type=checkbox]:checked+span::before {
    background-color: #00c292;
    border-color: #00c292
}
.checkbox-success input[type=checkbox]:checked+span::after {
    color: #fff
}
.checkbox-purple input[type=checkbox]:checked+span::before {
    background-color: #9675ce;
    border-color: #9675ce
}
.checkbox-purple input[type=checkbox]:checked+span::after {
    color: #fff
}
.checkbox-red input[type=checkbox]:checked+span::before {
    background-color: #fb9678;
    border-color: #fb9678
}
.checkbox-red input[type=checkbox]:checked+label::after {
    color: #fff
}
.checkbox-inverse input[type=checkbox]:checked+span::before {
    background-color: #4c5667;
    border-color: #4c5667
}
.checkbox-inverse input[type=checkbox]:checked+span::after {
    color: #fff
}
.radio {
    padding-left: 20px;
    cursor: pointer;
    font-weight: 500;
}
.radio span {
    display: inline-block;
    padding-left: 5px;
    position: relative
}
.radio span::before {
    -o-transition: border .5s ease-in-out;
    -webkit-transition: border .5s ease-in-out;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid rgba(120, 130, 140, .13);
    content: "";
    display: inline-block;
    height: 17px;
    left: 0;
    margin-left: -20px;
    position: absolute;
    transition: border .5s ease-in-out;
    width: 17px;
    outline: 0!important
}
.radio span::after {
    -moz-transition: -moz-transform .3s cubic-bezier(.8, -.33, .2, 1.33);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    -o-transition: -o-transform .3s cubic-bezier(.8, -.33, .2, 1.33);
    -webkit-transform: scale(0, 0);
    -webkit-transition: -webkit-transform .3s cubic-bezier(.8, -.33, .2, 1.33);
    background-color: #2b2b2b;
    border-radius: 50%;
    content: " ";
    display: inline-block;
    height: 7px;
    left: 5px;
    margin-left: -20px;
    position: absolute;
    top: 5px;
    transform: scale(0, 0);
    transition: transform .3s cubic-bezier(.8, -.33, .2, 1.33);
    width: 7px
}
.radio input[type=radio] {
    cursor: pointer;
    opacity: 0;
    z-index: 1;
    outline: 0!important
}
.radio input[type=radio]:disabled+span {
    opacity: .65
}
.radio input[type=radio]:focus+span::before {
    outline-offset: -2px;
    outline: -webkit-focus-ring-color auto 5px;
    outline: dotted thin
}
.radio input[type=radio]:checked+span::after {
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1)
}
.radio input[type=radio]:disabled+span::before {
    cursor: not-allowed
}
.radio.radio-inline {
    margin-top: 0
}
.radio.radio-single span {
    height: 17px
}
.radio-primary input[type=radio]+span::after {
    background-color: #ab8ce4
}
.radio-primary input[type=radio]:checked+span::before {
    border-color: #ab8ce4
}
.radio-primary input[type=radio]:checked+span::after {
    background-color: #ab8ce4
}
.radio-danger input[type=radio]+span::after {
    background-color: #fb9678
}
.radio-danger input[type=radio]:checked+span::before {
    border-color: #fb9678
}
.radio-danger input[type=radio]:checked+span::after {
    background-color: #fb9678
}
.radio-info input[type=radio]+span::after {
    background-color: #03a9f3
}
.radio-info input[type=radio]:checked+span::before {
    border-color: #03a9f3
}
.radio-info input[type=radio]:checked+span::after {
    background-color: #03a9f3
}
.radio-warning input[type=radio]+span::after {
    background-color: #fec107
}
.radio-warning input[type=radio]:checked+span::before {
    border-color: #fec107
}
.radio-warning input[type=radio]:checked+span::after {
    background-color: #fec107
}
.radio-success input[type=radio]+span::after {
    background-color: #00c292
}
.radio-success input[type=radio]:checked+span::before {
    border-color: #00c292
}
.radio-success input[type=radio]:checked+span::after {
    background-color: #00c292
}
.radio-purple input[type=radio]+span::after {
    background-color: #9675ce
}
.radio-purple input[type=radio]:checked+span::before {
    border-color: #9675ce
}
.radio-purple input[type=radio]:checked+span::after {
    background-color: #9675ce
}
.radio-red input[type=radio]+span::after {
    background-color: #fb9678
}
.radio-red input[type=radio]:checked+span::before {
    border-color: #fb9678
}
.radio-red input[type=radio]:checked+span::after {
    background-color: #fb9678
}


/* LOADER
========================= */
.loader-wrapper {
  position: fixed;
  bottom: 10px;
  left: -100px;
  padding: 8px;
  background: transparentize(#FFF, 0.85);
  z-index: 99999;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-duration: 0.3s;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  &.loading {
    -webkit-transform: translateX(116px);
    transform: translateX(116px);
  }
  span {
    color: #fff;
  }
  #loading-icon {
    display: table-cell;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    border: 5px solid rgba(0, 0, 0, 0);
    border-top: 5px solid #fff;
    border-bottom: 5px solid #fff;
    animation: spin 4s linear infinite, color 2s linear infinite;
  }
  #inner-loading-icon {
    margin: auto;
    width: 15px;
    height: 15px;
    border: 3px solid rgba(0, 0, 0, 0);
    border-top: 3px solid #fff;
    border-bottom: 3px solid #fff;
    animation: spin 2s linear infinite, color 2s linear infinite;
  }
  .loader {
    border-radius: 50%;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.navbar-nav {
  margin: 0;
}
.navbar-nav .nav-link,
.navbar-nav .nav-item .nav-link,
.navbar-item, .navbar-brand {
  padding: 0 18px !important;
  margin: 0 !important;
  line-height: 56px;
  white-space: nowrap;
  @extend .noselectable;
  .dropdown-menu {
    left: auto;
    right: 0;
    -webkit-transform-origin: top right;
    -moz-transform-origin: top right;
    -ms-transform-origin: top right;
    transform-origin: top right;
  }
}

.dropdown-menu {
    font-size: .875rem;
    color: rgba(0, 0, 0, .87);
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, .1);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
    li a {
      padding: 0 !important;
      color: inherit !important;
      font-size: 12px !important;
      font-weight: 500;
      display: inline-block !important;
    }
}
.dropdown-menu>li:not(.dropdown-item)>a {
    display: block;
    padding: 3px 20px
}
.dropdown-menu>li:not(.dropdown-item)>a:focus,
.dropdown-menu>li:not(.dropdown-item)>a:hover {
    background-color: rgba(0, 0, 0, .065)
}
.dropdown-menu>.divider {
    height: 1px;
    margin: .5rem 0;
    overflow: hidden;
    background-color: rgba(120, 130, 140, .13)
}
.modal-open-aside,
.modal.inactive {
    overflow: visible
}
.dropdown-menu .active {
    background-color: rgba(0, 0, 0, .065)
}
.dropdown-menu.pull-left {
    left: auto!important;
    right: 100%
}
.dropdown-menu.pull-right {
    left: auto;
    right: 0;
    -webkit-transform-origin: top right;
    -moz-transform-origin: top right;
    -ms-transform-origin: top right;
    transform-origin: top right
}
.dropdown-menu.pull-up {
    top: 0
}
.dropdown-menu.pull-down {
    top: 100%!important;
    left: 0!important
}
.dropup .dropdown-menu {
    -webkit-transform-origin: bottom left;
    -moz-transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    transform-origin: bottom left
}
.dropup .dropdown-menu.pull-right {
    -webkit-transform-origin: bottom right;
    -moz-transform-origin: bottom right;
    -ms-transform-origin: bottom right;
    transform-origin: bottom right
}
.dropdown-menu.datepicker,
.dropdown-menu.timepicker {
    padding: 6px;
    font-size: .8rem
}
.dropdown-menu.datepicker .btn,
.dropdown-menu.timepicker .btn {
    font-size: .8rem
}
.dropdown-menu.datepicker .btn.btn-primary,
.dropdown-menu.timepicker .btn.btn-primary {
    background-color: #6887ff;
    color: #fff
}
.dropdown-menu.datepicker .btn:not(:hover),
.dropdown-menu.timepicker .btn:not(:hover) {
    box-shadow: 0 0 1px transparent
}
.dropdown-item {
    color: inherit
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  color: #373a3c;
  text-align: inherit;
  white-space: nowrap;
  background: 0 0;
  border: 0;
  &:hover {
    color: inherit !important;
  }
}
.dropdown-divider {
  height: 1px;
  margin: .5rem 0;
  overflow: hidden;
  background-color: #e5e5e5;
}
.dropdown-item:focus,
.dropdown-item:hover {
    color: inherit;
    background-color: rgba(0, 0, 0, .065)
}
.dropdown-divider {
    background-color: rgba(120, 130, 140, .13)
}
.dropdown-menu-scale {
    display: block;
    opacity: 0;
    -webkit-transform: scale(.8);
    -ms-transform: scale(.8);
    transform: scale(.8);
    -webkit-transition: all 250ms cubic-bezier(.24, .22, .015, 1.56);
    transition: all 250ms cubic-bezier(.24, .22, .015, 1.56);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    pointer-events: none
}
.open>.dropdown-menu-scale {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    pointer-events: auto;
    display: block!important
}
.dropdown-header {
    padding: 8px 16px
}
.dropdown-submenu {
    position: relative
}
.dropdown-submenu:focus>.dropdown-menu,
.dropdown-submenu:hover>.dropdown-menu {
    display: block
}
.dropdown-submenu .dropdown-menu {
    left: 100%;
    top: 0;
    margin-top: -6px;
    margin-left: -1px
}
.dropup .dropdown-submenu>.dropdown-menu {
    top: auto;
    bottom: 0;
    margin-bottom: -6px
}
.popover {
    color: rgba(0, 0, 0, .87)
}
@media (max-width: 543px) {
    .pull-none-xs {
        float: none!important
    }
    .dropdown-menu.pull-none-xs {
        left: 0
    }
}

.open {
  & > .dropdown-menu-scale {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    pointer-events: auto;
    display: block!important;
  }
}

.app {
  .app-aside {
    width: 80px;
    height: 100%;
    float: left;
    position: fixed;
    top: 56px;
    right: 0;
    bottom: 0;
    left: -80px;
    z-index: 99999999999;
    opacity: 1;
    display: block;
    overflow: visible;
    outline: 0;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-duration: 0.3s;
    @media (max-width: 991px) {
      .app-aside.modal {
        width: 100%;
        position: fixed;
        z-index: 1050;
      }
      .app-aside .left {
        position: fixed;
        width: 304px;
      }
    }
    & > .left {
      position: absolute;
      z-index: 1055;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      color: rgba(255, 255, 255, 0.87);
      background-color: $clr-dark;
      .navbar {
        border: none;
        margin: 0;
        padding: 0 1rem;
        flex-shrink: 0;
        min-height: 0;
        border-radius: 0 !important;
        overflow: hidden;
        text-align: center;
        padding-left: 4px;
        padding-right: 4px;
        .navbar-brand {
          padding: 0;
          font-size: 20px;
          font-weight: normal;
          float: none;
          margin-right: 0;
          color: rgba(255, 255, 255, 0.87);
          i {
            color: #fff;
            font-size: 40px;
            line-height: 57px;
            text-shadow: longshadow($clr-darkDK, $clr-darkDK, 40, 0.5)
          }
        }
      }
      ul.nav {
        padding-left: 0;
        padding-right: 0;
        list-style: none;
        border: inherit;
         li {
          position: relative;
          &.header {
            line-height: 25px;
            background-color: rgba(0, 0, 0, 0.25);
            text-align: center;
            span {
              font-size: 10px;
              color: rgba(255,255,255,0.5);
              text-transform: uppercase;
              letter-spacing: 3px;
              cursor: default;
            }
          }
          &:not(.header):hover {
            outline: none;
            box-shadow: none;
            //border: none;
            background-color: $clr-primary;
            /*&:nth-child(1) { background-color: $clr-primary; }
            &:nth-child(2) { background-color: $clr-success; }
            &:nth-child(3) { background-color: $clr-warning; }
            &:nth-child(4) { background-color: $clr-danger; }
            &:nth-child(5) { background-color: $clr-mint; }*/
            ul.child_menu {
              width: 310px;
              li {
                background-color: transparent;
                a:hover {
                  background-color: rgba(0,0,0,0.05) !important;
                }
              }
            }
          }
          &.active {
            background-color: rgba(0, 0, 0, 0.65);
            a {
              color: rgba(255, 255, 255, 0.87) !important;
              background-color: $clr-primary !important;
              -webkit-box-shadow: inset -4px 0px 0px 0px $clr-primaryLT;
              -moz-box-shadow:    inset -4px 0px 0px 0px $clr-primaryLT;
              box-shadow:         inset -4px 0px 0px 0px $clr-primaryLT;
              .nav-icon {
                color: #fff;
                  //text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.15);
              }
              .nav-text {
                opacity: 1;
              }
            }
          }
          a {
            display: block;
            padding: 0 1rem;
            position: relative;
            color: rgba(255, 255, 255, 0.87);
            cursor: pointer;
            text-align: center;
            @media (min-width: 992px) {
              padding-left: 4px;
              padding-right: 4px;
            }
            .nav-icon {
              text-align: center;
              float: none;
              margin-left: 0;
              margin-right: 0;
              position: relative;
              i {
                padding: 0;
                margin: 10px;
                position: relative;
                font-size: 25px;
                width: 40px;
                height: 40px;
                line-height: 40px;
                border-radius: 50%;
                background: rgba(0, 0, 0, 0.15);
              }
            }
            .nav-text {
              display: block;
              line-height: 18px;
              padding: 10px 0;
              opacity: 0.75;
              font-weight: 500;
              margin-top: -18px;
              font-size: 0.85em;
            }
            &::after {
              content: "";
              display: table;
              clear: both;
            }
            &:hover, &:active, &:focus {
              background-color: rgba(0, 0, 0, 0.15);
            }
          }
          /*&:nth-child(1) ul.child_menu, &:nth-child(1) > a:hover, &:nth-child(1) > a:active, &:nth-child(1) > a:focus { background-color: $clr-primary; }
          &:nth-child(2) ul.child_menu, &:nth-child(2) > a:hover, &:nth-child(2) > a:active, &:nth-child(2) > a:focus { background-color: $clr-success; }
          &:nth-child(3) ul.child_menu, &:nth-child(3) > a:hover, &:nth-child(3) > a:active, &:nth-child(3) > a:focus { background-color: $clr-warning; }
          &:nth-child(4) ul.child_menu, &:nth-child(4) > a:hover, &:nth-child(4) > a:active, &:nth-child(4) > a:focus { background-color: $clr-danger; }
          &:nth-child(5) ul.child_menu, &:nth-child(5) > a:hover, &:nth-child(5) > a:active, &:nth-child(5) > a:focus { background-color: $clr-mint; }*/
          ul.child_menu {
            width: 0;
            min-height: 80px;
            overflow: hidden;
            list-style: none;
            position: fixed;
            left: 80px;
            top: 0;
            bottom: 0;
            color: rgba(0, 0, 0, 0.75);
            //background-color: $clr-blackDK;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
            box-sizing: content-box;
            transition-property: width;
            transition-duration: 0.3s;
            li {
              width: 100%;
              padding: 0;
              position: relative;
              display: block;
              border-bottom: 1px solid rgba(255,255,255,0.15);
              &.subheader {
                background: transparent;
                border-bottom: 1px solid rgba(255,255,255,0.15);
                padding: 18px;
                height: 56px;
                font-size: 18px;
                font-weight: 600;
                color: rgba(255,255,255,0.85);
              }
              a {
                margin: 0;
                text-decoration: none;
                padding: 10px 20px;
                color: rgba(255, 255, 255, 0.85);
                text-align: left;
                .app-icon {
                  width: 64px;
                  height: 58px;
                  margin-top: 6px;
                  display: inline-block;
                  vertical-align: top;
                  font-size: 50px;
                  background-size: contain;
                  background-position: center;
                }
                .nav-text {
                  font-size: 13px;
                  text-align: center;
                  display: block;
                  padding: 0 5px;
                  margin: 0;
                  line-height: 20px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  color: $clr-darkLT;
                }
                &:hover {
                  background-color: rgba(0,0,0,0.15);
                  span {
                    //opacity: 1;
                  }
                }
              }
            }
            i {
              font-size: 250px;
              position: absolute;
              bottom: 0;
              right: -45px;
              color: rgba(0,0,0,0.15);
              cursor: default;
              z-index: -1;
            }
          }
        }
      }
    }
  }
  &.sidebar-full {
    .app-aside {
      width: 250px;
      left: -250px;
      & > .left {
        ul.nav {
           li {
            &:focus {
              ul.child_menu {
                left: 250px;
              }
            }
            a {
              text-align: left;
              .nav-icon {
                i {
                  width: 36px;
                  height: 36px;
                  line-height: 36px;
                }
              }
              .nav-text {
                display: inline-block;
                position: relative;
                top: -5px;
                font-size: 14px;
              }
              & > i {
                position: absolute;
                right: 6px;
                top: 12px;
              }
            }
            ul.child_menu {
              left: 250px;
            }
          }
        }
      }
    }
    .app-content {
      .app-header {
        .navbar {
          .menu-toggle {
            width: 56px;
            padding: 8px !important;
            position: relative;
          }
          #pageTitle {
            width: 194px;
            color: $clr-carbon;
            //background-color: $clr-pink;
          }
        }
      }
    }
  }
  .app-content {
    margin-left: 0;
      @media #{$device-tablet} {
        margin-left: 0;
      }
    .app-header {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      margin: inherit;
      z-index: 1020;
      background-color: transparent;
      .navbar {
        border: none;
        margin: 0;
        padding: 0;
        flex-shrink: 0;
        max-height: 56px;
        background-color: #fff;
        border-radius: 0;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.03), 0 1px 0 rgba(0, 0, 0, 0.03);
        .menu-toggle {
          width: 80px;
          height: 56px;
          padding: 8px 18px !important;
          text-align: center;
          background: $clr-orange;
          i {
            display: inline-block;
            padding: 0;
            width: 40px;
            height: 40px;
            line-height: 40px;
            font-size: 28px;
            text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
            border-radius: 50%;
            background: rgba(0,0,0,0.15);
            color:#fff;
            transition-property: -webkit-transform;
            transition-property: transform;
            transition-duration: 0.3s;
          }
          span {display: none;}
          &:hover {
            i {
              //color: #fff;
            }
          }
        }
        #pageTitle {
          font-size: 20px;
          font-size: 16px;
          font-weight: 800;
          color: $clr-carbon;
          text-transform: uppercase;
        }
        li {
          a {
            max-height: 56px;
            padding: 8px 20px;
            color: $clr-carbon;
            font-size: 14px;
            font-weight: 500;
            display: block;
            @extend .noselectable;
            .avatar {
              width: 32px;
              height: 32px;
              border-radius: 16px;
              display: inline-block;
              background-color: #eee;
              background-size: cover;
              background-position: center;
            }
            .username {
              position: relative;
              top: -12px;
              margin-left: 5px;
              color: #777;
            }
            &:hover,
            &:active,
            &:focus {
              background: $clr-orange;
              color: #fff !important;
              .label {
                color: $clr-carbon;
                background-color: #fff;
              }
            }
            i {
              padding: 16px 0;
            }
            .label {
              position: absolute;
              bottom: 2px;
              width: 20px;
              right: 2px;
              height: 20px;
              line-height: 22px;
              padding: 0;
              text-align: center;
              border-radius: 10px;
              background: $clr-danger;
            }
          }
          ul.app-user-menu {
            width: 300px;
            position: fixed;
            top:56px;
            bottom: 0;
            right: -300px;
            background-color: $clr-darkLT;
            -webkit-transform: translateX(0);
            transform: translateX(0);
            transition-property: -webkit-transform;
            transition-property: transform;
            transition-duration: 0.3s;
            li {
              &.user-panel {
                padding: 20px;
                background-color: darken($clr-primaryDK, 10%);
                .image > .img {
                  width: 60px;
                  height: 60px;
                  display: block;
                  background-color: #fff;
                  background-size: cover;
                  background-position: center;
                  border-radius: 50%;
                }
                .info {
                  padding: 10px;
                  line-height: 1;
                  position: absolute;
                  left: 86px;
                  a {
                    color: #fff;
                    text-decoration: none;
                    padding-right: 5px;
                    margin-top: 3px;
                    font-size: 11px;
                  }
                  p {
                    font-weight: 600;
                    margin-bottom: 9px;
                  }
                  .lblName {
                    font-size: 15px;
                    font-weight: 500;
                    color: #FFFFFF;
                    text-align: left;
                    padding-bottom: 0px;
                    margin-top: 5px;
                    margin-bottom: 0px;
                  }
                  .lblLocation {
                    font-size: 12px;
                    font-weight: 300;
                    color: rgba(255, 255, 255, 0.75);
                  }
                }
                &:after {
                  clear: both;
                }
              }
              &.header {
                line-height: 25px;
                background-color: darken($clr-darkLT, 15%);
                text-align: left;
                padding: 0 20px;
                span {
                  font-size: 10px;
                  color: rgba(255,255,255,0.5);
                  text-transform: uppercase;
                  letter-spacing: 3px;
                  cursor: default;
                }
              }
              a {
                padding: 8px 20px;
                color: #fff;
                font-size: 14px;
                font-weight: 500;
                display: block;
                border-bottom: 1px solid rgba(255,255,255,0.15);
                position: relative;
                &:hover, &:active, &:focus {
                  color: #fff !important;
                  background-color: rgba(255,255,255,0.1);
                }
                i {
                  color: #fff !important;
                  background-color: rgba(255,255,255,0.15);
                  position: absolute;
                  top: 0;
                  right: 0;
                  width: 40px;
                  height: 40px;
                  padding: 0;
                  line-height: 40px;
                  font-size: 20px;
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
    .app-body {
      //padding: 56px 0 44px;
      padding: 56px 0 66px;
      position: relative;
      z-index: 10;
      transition-property: -webkit-transform;
      transition-property: transform;
      transition-duration: 0.3s;
      .padding-25:not(:first-child) {
        //padding-top: 0 !important;
      }
    }
    .app-footer {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1020;
      margin: inherit;
      background-color: $clr-darkDK;
      box-shadow: 0 -2px 2px rgba(0,0,0,0.03), 0 1px 0 rgba(0,0,0,0.03);
      .foot-container {
        padding: 8px 15px;
        color: #fff;
        a:not(.nav-link) {
          padding: 4px 12px;
          cursor: pointer;
          display: inline-block;
          border-radius: 4px;
          margin-left: 10px;
          //border: 1px solid transparent;
          &:hover {
            //border: 1px solid rgba(0, 0, 0, 0.15);
            background-color: rgba(0, 0, 0, 0.15);
          }
        }
      }
    }
  }
}

/*  MENUS LATERALES ABIERTOS
=============================================== */
body.left-menu-open {
  .menu-toggle {
    background-color: $clr-primary !important;
    i {
      -ms-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
    }
  }
  .app-aside,
  .app-body {
    -webkit-transform: translateX(80px);
    transform: translateX(80px);
  }
  .sidebar-full {
    .app-aside,
    .app-body {
      -webkit-transform: translateX(250px);
      transform: translateX(250px);
    }
  }
  /*.app-body {
    -webkit-transform: translateX(40px);
    transform: translateX(40px);
  }*/
}

body.right-menu-open {
  a.user-profile {
    background: $clr-orange;
    color: #fff !important;
  }
  .app-user-menu,
  .app-body {
    -webkit-transform: translateX(-300px) !important;
    transform: translateX(-300px) !important;
  }
  /*.app-body {
    -webkit-transform: translateX(-150px);
    transform: translateX(-150px);
  }*/
}


/*  EN CONSTRUCCION
=============================================== */
.warning-content {
  position:absolute;
  top:25%;
  width:100%;
  height:300px;
  text-align:center;
  margin:0;
  h1 {
    margin-bottom: 20px;
  }
}

/*  LOGIN
=============================================== */
.login-register {
  width: 100%;
  max-width: 100% !important;
  height: 100%;
  margin: 0px !important;
  position: fixed !important;
  right: auto !important;
  background: url(../img/bg-login.jpg) center center/cover no-repeat!important;
  .overlay {
    background-color: rgba(0,0,0,0.35);
  }
  .login {
    padding: 25px;
    .title {
      color: #fff;
      height: 60px;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: -0.02em;
    }
    .fields {
      i {
        position: absolute;
        z-index: 1;
        left: 36px;
        top: 8px;
        opacity: .5;
        font-size: 18px;
        width: 18px;
        text-align: center;
      }
      .form-group {
        padding: 18px 0 0;
        position: relative;
        .form-control {
          background: 0 0;
          position: relative;
          z-index: 5;
          border: 0;
          box-shadow: none;
          border-radius: 0;
          border-bottom: 1px solid rgba(160,160,160,.2);
          width: 100%;
          height: 34px;
          padding: 2px;
          color: inherit;
          & + label {
            -webkit-transition: all .2s;
            transition: all .2s;
            position: absolute;
            z-index: 0;
            opacity: .5;
            left: 0;
            font-weight: 500;
          }
        }
        &.float-label {
          .form-control + label {
            top: 20px;
            font-size: 1em;
          }
          .form-control:focus + label,
          .form-control.has-value + label {
            top: 0;
            font-size: .85em;
            color: $clr-primary;
            opacity: .8;
          }
          .form-control:focus {
            border-color: $clr-primary;
          }
        }
      }
      .user, .password, .remember {
        position:relative;
      }
      .remember {
        left: 0;
        width: 80%;
        right: 0;
        margin: 5px auto 0;
      }
      .submit {
        position: relative;
        top: 5px;
        left: 0;
        width: 80%;
        right: 0;
        margin: auto;
        .forgot{
          float: right;
          font-size: 10px;
          margin-top: 11px;
          text-decoration: underline;
          a{
            color: $clr-primary;
          }
        }
        input {
          text-transform: uppercase;
          -webkit-transition: all 0.3s ease;
          transition: all 0.3s ease;
          padding: 13px 30px;
          font-size: 11px;
          font-weight: 700;
          font-family: $font-montserrat;
          line-height: 1.5em;
          border-radius: 2px;
          transition-property: background, color;
          transition-duration:.2s;
          &:focus {
            box-shadow:none;
            outline:none;
          }
          &:hover {
            color: white;
            background: $clr-primaryDK;
            cursor: pointer;
            transition-property:background, color;
            transition-duration: .2s;
          }
        }
      }
    }
  }
  .login-box {
    width: 240px;
    height: 464px;
    border-top: 2px solid $clr-primary;
    margin: auto;
    padding: 40px;
    background-color: transparentize(#fff, .1);
    position: absolute;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    top: 50%;
    left: 50%;
    margin-top: -273px;
    margin-left: -160px;
    .login-logo {
      &.margin-top-25 {
        margin-top: 0 !important;
      }
      .logo {
        width: 100%;
        height: 180px;
        margin-bottom: 20px;
        background-image: url(../img/logo.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    .box-title {
      height: 50px;
      margin: 10px 0 !important;
      text-align: center;
      font-size: 18px;
    }
    small {
      display: none;
    }
    .fields {
      width: 100%;
      height: 215px;
      position: absolute;
      left: 0;
      margin-bottom: 5px;
      .form-group {
        padding: 0;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        &.margin-bottom-50 {
          margin-bottom: 70px !important;
        }
        .form-control {
          width: 100%;
          height: 42px;
          margin-top: -2px;
          left: 0;
          padding: 10px 30px 10px 130px;
          border-top: 2px solid rgba(0,0,0,0.35);
          border-bottom: 2px solid rgba(0,0,0,0.35);
          border-right: none;
          border-left: none;
          outline: none;
          box-shadow: none;
        }
        &.float-label {
          .form-control + label {
            top: 10px;
            font-size: 1em;
            left: 30px;
          }
          .form-control:focus + label {
            top: 10px;
            font-size: 1em;
            color: $clr-primary;
            opacity: 1;
          }
        }
        &.remember, &.submit {
          width: 256px;
          margin: 5px auto 0 !important;
        }
      }
    }
    .forgot {
      margin-top: 192px;
    }
    .form-group {
      .text-left {
        text-align: center !important;
        p {
          margin: 0 !important;
        }
      }
    }
    .validation {
      position:absolute;
      z-index:1;
      right:10px;
      top:6px;
      opacity:0;
    }
  }
  .login-sidebar {
    width: 400px;
    background-color: transparentize(#fff, .1);
    position: absolute;
    right: 0;
    margin-top: 0;
    height: 100%;
    .logo {
      width: 100%;
      height: 180px;
      margin-bottom: 20px;
      background-image: url(../img/logo.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
    .love {
      p, a {
        //color: $clr-carbon;
      }
    }
  }
  #recoverform {
    display: none;
  }

  .disclaimer{
    position: absolute;
    bottom: 20px;
    left: 35px;
    width: 250px;
    color: #5B5E6F;
    font-size: 10px;
    text-align: left;
  }
  .love {
    position: fixed;
    left: 20px;
    bottom: 0px;
    font-size: 14px;
    font-weight: 500;
    p {
      color: white;
      font-weight: 500;
    }
    a {
      color: white;
      font-weight: 700;
      text-decoration: none;
    }
    img {
      position: relative;
      top: 3px;
      margin: 0px 4px;
      width: 10px;
    }
  }
}

/* PRINTING STYLES
============================ */
@media print {

  a[href]:after {
    content: none !important;
  }

  .form-control {
    border-width: 0 0 1px 0 !important;
  }

  .padding-25 {
    padding: 0 !important;
  }

  .app {
    .app-aside {
      display: none !important;
    }
    .app-content {
      .app-header,
      .app-footer {
        display: none !important;
      }
      .app-body {
        padding: 0 !important;
        .box {
          .box-header {
            display: none !important;
          }
          .box-body {
            .b-b {
              display: none !important;
            }
            .table-responsive {
              .form-inline {
                .mdl-grid:first-child,
                .mdl-grid:last-child {
                  display: none !important;
                }
                .table  {
                  td, th {
                  	padding: 5px !important;
                  	font-size: 8px !important;
                    text-align: left !important;
                    &::before, &::after {
                      content: '' !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .vista-impresion {
    width: 21cm !important;
    padding: 0 !important;
    margin: 0 auto !important;
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    font-family: Arial, Helvetica, sans-serif !important;
    h4, p, span {
      font-family: Arial, Helvetica, sans-serif !important;
    }
    p, span {
      font-size: 12pt;
    }
    .form-group {
      margin-bottom: 0;
      .control-label {
        padding-top: 7px;
      }
      .form-control {
        padding: 0;
        height: 28px;
      }
    }
  }

  .page {
    page-break-after: always;
    page-break-inside: avoid;
  }

}
